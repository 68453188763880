/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../Styles/SalesModal.css';
import { X, Check, Star } from 'lucide-react';

const SalesModal = ({ feature, onClose }) => {
  const [currency, setCurrency] = useState('usd');
  const [billingCycle, setBillingCycle] = useState('monthly');

  const pricingTiers = [
    { 
      name: 'Premium', 
      price: { 
        usd: { monthly: '$5.59', annual: '$49.99' },
        cad: { monthly: '$6.99', annual: '$59.99' }
      },
      features: [
        'Increased interaction limits',
        'Access to advanced AI capabilities',
        'Personalized study plans',
        '7-day free trial'
      ]
    },
    { 
      name: 'Pro', 
      price: { 
        usd: { monthly: '$12.99', annual: '$119.99' },
        cad: { monthly: '$15.99', annual: '$139.99' }
      },
      features: [
        'Unlimited AI interactions',
        'Up to 3 PDF uploads per chat',
        'Advanced research tools',
        '7-day free trial'
      ]
    },
    { 
      name: 'Ultimate', 
      price: { 
        usd: { monthly: '$18.99', annual: '$159.99' },
        cad: { monthly: '$21.99', annual: '$189.99' }
      },
      features: [
        'Priority response times',
        'Access to beta features',
        'Up to 5 PDF uploads per chat',
        '7-day free trial'
      ]
    }
  ];

  useEffect(() => {
    const detectUserLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        if (data.country_code === 'CA') {
          setCurrency('cad');
        } else {
          setCurrency('usd');
        }
      } catch (error) {
        console.error('Error detecting user location:', error);
        setCurrency('usd');
      }
    };

    detectUserLocation();
  }, []);

  return (
    <div className="sales-modal-overlay" onClick={onClose}>
      <div className="sales-modal-content" onClick={e => e.stopPropagation()}>
        <button className="sales-modal-close" onClick={onClose}>
          <X size={24} />
        </button>
        <h2>Unlock {feature}</h2>
        <p className="modal-description">Choose the plan that fits your learning needs and take your studies to the next level!</p>
        
        <div className="plans-grid">
          {pricingTiers.map((tier) => (
            <div key={tier.name} className={`plan-card ${tier.name.toLowerCase()}`}>
              {tier.name === 'Ultimate' && <Star className="ultimate-star" size={24} />}
              <h3>{tier.name}</h3>
              <p className="plan-price">
                Starting at {tier.price[currency][billingCycle]}/{billingCycle === 'monthly' ? 'month' : 'year'}
              </p>
              <ul className="feature-list">
                {tier.features.map((item, index) => (
                  <li key={index}><Check size={18} /> {item}</li>
                ))}
              </ul>
              <Link to="/pricing" className="upgrade-button">Choose {tier.name}</Link>
            </div>
          ))}
        </div>
        
        {window.innerWidth <= 568 && (
          <div className="modal-footer">
            <p>Not sure which plan is right for you?</p>
            <Link to="/pricing" className="view-all-plans">View All Plans</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesModal;
