/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft, FaExternalLinkAlt, FaCopy, FaChevronUp, FaMagic, FaInfoCircle, FaPaperPlane, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MarkdownRenderer, AIResponseRenderer } from './LLMTextRenderer';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import '../../Styles/Expanded.css';
import { toast } from 'react-toastify';

const ExpandedResource = ({ resource, onImprove, onExplain, onClose, onSendMessage, allVersions }) => {
  const [isCode, setIsCode] = useState(resource.type === 'code');
  const [ispdf, setIspdf] = useState(resource.type === 'pdf');
  const [selectedText, setSelectedText] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState({ top: 0, left: 0 });
  const [isImproveMode, setIsImproveMode] = useState(false);
  const [improveSuggestion, setImproveSuggestion] = useState('');
  const contentRef = useRef(null);
  const improveInputRef = useRef(null);
  const optionsRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    if (allVersions && allVersions.length > 0) {
      const sortedVersions = [...allVersions].sort((a, b) => b.version - a.version);
      setVersions(sortedVersions);
      // Find the index of the specified resource
      const resourceIndex = sortedVersions.findIndex(v => v.id === resource.id);
      setCurrentIndex(resourceIndex !== -1 ? resourceIndex : 0);
    } else {
      setVersions([resource]);
      setCurrentIndex(0);
    }
    console.log('Versions set:', allVersions);
    console.log('Current resource:', resource);
  }, [allVersions, resource]);

  useEffect(() => {
    const handleMouseUp = () => {
      // All functionality commented out
    };

    const handleClickOutside = (event) => {
      // All functionality commented out
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resource.type]);

  const toggleVersionControl = (direction) => {
    if (versions.length <= 1) return;

    let newIndex;
    if (direction === 'previous' && currentIndex < versions.length - 1) {
      newIndex = currentIndex + 1;
    } else if (direction === 'next' && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else {
      return;
    }
    setCurrentIndex(newIndex);
    console.log('Version toggled. New index:', newIndex);
  };

  const currentResourceVersion = versions[currentIndex] || resource;

  // Comment out unused functions
  /*
  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentResourceVersion.content);
    toast.success('Content copied to clipboard!');
  };

  const handleImprove = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    console.log('Improve button clicked');
    setIsImproveMode(true);
    console.log('Improve mode set to:', true);
  };

  const handleExplain = (e) => {
    const message = `Please explain this text: <quote>${selectedText}</quote>`;
    onExplain(message);
    setShowOptions(false);
  };

  const handleSendImprovement = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    console.log('Send improvement button clicked');
    const message = `I want to improve this text: <quote>${selectedText}</quote>. Here are my thoughts on what to improve: ${improveSuggestion}`;
    console.log('Sending improve message:', message);
    onImprove(message);
    setShowOptions(false);
    setIsImproveMode(false);
    setImproveSuggestion('');
    toast.info('Improvement request sent!');
  };
  */

  // New function to truncate text
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  return (
    <div className="expanded-resource">
      <div className="expanded-resource-header">
        <button className="back-button" onClick={onClose}>
          <FaArrowLeft /> 
        </button>
        <h2 title={currentResourceVersion.title}>{truncateText(currentResourceVersion.title, 35)}</h2>
        {currentResourceVersion.type === 'pdf' && (
          <a 
            href={currentResourceVersion.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="pdf-link"
          >
            View Original PDF <FaExternalLinkAlt />
          </a>
        )}
        {/* Comment out copy button
        {(isCode || currentResourceVersion.type === 'essay') && (
          <button className="copy-button" onClick={copyToClipboard}>
            <FaCopy /> Copy
          </button>
        )}
        */}
      </div>
      <div 
        ref={contentRef}
        className={`expanded-resource-content ${isCode ? 'code-content' : ''} ${ispdf ? 'pdf-content' : 'not-pdf-content'} ${currentResourceVersion.type === 'essay' || (!isCode && !ispdf) ? 'essay-content' : ''}`}
      >
        {currentResourceVersion.type === 'pdf' ? (
          <div>{currentResourceVersion.content}</div>
        ) : isCode ? (
          <SyntaxHighlighter
            language={currentResourceVersion.language || 'text'}
            wrapLines={true}
            wrapLongLines={true}
            customStyle={{
              marginTop: '-30px',
              borderRadius: '8px',
              padding: '1rem',
              maxHeight: '100vh',
              overflowY: 'auto',
              width: '100%',
              height: '100vh',
              backgroundColor: '#f5f5f5',
            }}
          >
            {currentResourceVersion.content}
          </SyntaxHighlighter>
        ) : (
          <div className="essay-text">{currentResourceVersion.content}</div>
        )}
      </div>
      {/* Comment out text options
      {showOptions && (
        <div 
          ref={optionsRef}
          className="text-options"
          style={{
            position: 'fixed',
            top: `${optionsPosition.top}px`,
            left: `${optionsPosition.left}px`,
            zIndex: 1000,
          }}
        >
          {isImproveMode ? (
            // ... improve mode input ...
          ) : (
            <>
              <button onClick={handleExplain} className="option-button explain">
                <FaInfoCircle /> Explain
              </button>
            </>
          )}
        </div>
      )}
      */}
      {currentResourceVersion.type !== 'pdf' && versions.length > 0 && (
        <div className="smart-footer">
          <div className="version-info">
            <span className="version-number">
              Version {versions.length - currentIndex} of {versions.length}
            </span>
          </div>
          {versions.length > 1 && (
            <div className="version-toggle">
              <FaChevronLeft 
                className={`version-toggle-icon ${currentIndex >= versions.length - 1 ? 'disabled' : ''}`} 
                onClick={() => toggleVersionControl('previous')} 
                style={{ cursor: currentIndex < versions.length - 1 ? 'pointer' : 'not-allowed', opacity: currentIndex < versions.length - 1 ? 1 : 0.3 }}
              />
              <span>Toggle Version</span>
              <FaChevronRight 
                className={`version-toggle-icon ${currentIndex <= 0 ? 'disabled' : ''}`} 
                onClick={() => toggleVersionControl('next')} 
                style={{ cursor: currentIndex > 0 ? 'pointer' : 'not-allowed', opacity: currentIndex > 0 ? 1 : 0.3 }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExpandedResource;
