import axios from 'axios';

const API_URL = 'https://api.studybuddy.lekan.ca';

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const makeRequest = async (method, url, data = null, config = {}) => {
  return axiosInstance({
    method,
    url,
    data,
    ...config,
  });
};

export const signUp = async (userData) => {
  try {
    const response = await makeRequest('post', '/users/register', userData);
    localStorage.setItem('token', response.data.token);
    return response.data;
  } catch (error) {
    console.error('SignUp error:', error.response?.data || error.message);
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const response = await makeRequest('post', '/users/login', { email, password });
    localStorage.setItem('token', response.data.token);
    return response.data.user;
  } catch (error) {
    console.error('Login error:', error.response?.data || error.message);
    throw error;
  }
};

export const getProfile = async () => {
  try {
    const response = await makeRequest('get', '/users/profile');
    return {
      ...response.data,
      memoryArray: Array.isArray(response.data.memoryArray) 
        ? response.data.memoryArray 
        : JSON.parse(response.data.memoryArray || '[]')
    };
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const deleteAccount = async () => {
  try {
    const response = await makeRequest('delete', '/users/delete-account');
    localStorage.removeItem('token'); // Remove the token after successful deletion
    return response.data;
  } catch (error) {
    console.error('Account deletion error:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteChat = async (chatId) => {
  try {
    const response = await makeRequest('delete', `/chats/${chatId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting chat:', error);
    throw error;
  }
};

export const createChat = async (title) => {
  return await makeRequest('post', '/chats', { title });
};

export const updateNotificationPreferences = async () => {
  // Implement notification preferences update logic
};

export const changePassword = async (currentPassword, newPassword) => {
  try {
    const response = await makeRequest('put', '/users/change-password', { current_password: currentPassword, new_password: newPassword });
    return response.data;
  } catch (error) {
    console.error('Change password error:', error.response?.data || error.message);
    throw error;
  }
};

export const renameChat = async (chatId, newTitle) => {
  return await makeRequest('put', `/chats/${chatId}`, { title: newTitle });
};


export const getMessages = async (chatId) => {
  return await makeRequest('get', `/chats/${chatId}/messages`);
};

export const createCheckoutSession = async (tier, interval, currency) => {
  try {
    const response = await makeRequest('post', '/create-checkout-session', { tier, interval, currency});
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Failed to create checkout session', response.data);
      throw new Error('Failed to create checkout session');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const handleStripePortalRedirect = async () => {
  try {
    const response = await makeRequest('post', '/create-customer-portal-session');
    const data = response.data;
    if (response.status === 200) {
      window.location.href = data.url;
    } else {
      console.error('Failed to redirect to Stripe portal:', data.error);
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export function askAITutor(subject, question, onChunk, onError, onComplete) {
  const url = new URL(`${API_URL}/chats/homepage`);
  url.searchParams.append('subject', subject);
  url.searchParams.append('question', question);

  const eventSource = new EventSource(url.toString());

  let fullResponse = '';

  eventSource.onmessage = (event) => {
    try {
      console.log('Received message:', event.data);
      const data = JSON.parse(event.data);

      if (data.response !== undefined && data.response !== '') { 
        const chunk = data.response;
        fullResponse += chunk;
        onChunk(chunk);
      } else if (data.response === '') {
        console.warn('Received an empty response');
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      console.error('Error parsing SSE message:', error);
      onError('Error parsing AI response');
      eventSource.close();
    }
  };

  eventSource.onerror = (error) => {
    console.error('EventSource failed:', error);
    onError('An error occurred while fetching the response. Please try again.');
    eventSource.close();
  };

  eventSource.addEventListener('end', () => {
    console.log('Stream ended');
    onComplete(fullResponse);
    eventSource.close();
  });

  return () => {
    eventSource.close();
  };
}

export async function askAITutorNonStreaming(subject, question) {
  try {
    const data = { subject, question };
    console.log('Sending data:', JSON.stringify(data));

    const response = await makeRequest('post', '/chats/homepage', data);

    console.log('Received response:', response.data);
    return { response: response.data.response, error: null };
  } catch (error) {
    console.error('Error asking AI Tutor:', error);
    
    let errorMessage = 'An unexpected error occurred. Please try again.';

    if (axios.isAxiosError(error)) {
      console.log('Axios error:', error.toJSON());
      if (error.response) {
        console.log('Error response:', error.response.data);
        errorMessage = error.response.data?.error || `Server error: ${error.response.status}`;
        if (error.response.status === 404) {
          errorMessage = 'The AI Tutor service is not available at the moment. Please try again later.';
        }
      } else if (error.request) {
        errorMessage = 'No response received from server. Please check your connection.';
      } else {
        errorMessage = error.message || 'An error occurred while setting up the request.';
      }
    }

    return { 
      response: null, 
      error: errorMessage
    };
  }
}

export const updateProfile = async (profileData) => {
  try {
    const response = await makeRequest('put', '/users/profile', profileData);
    return response.data;
  } catch (error) {
    console.error('Profile update error:', error.response?.data);
    throw error;
  }
};

export const fetchStudyPlans = async () => {
  return;
};

export const fetchChats = async () => {
  console.log('fetchChats called');
  try {
    const timestamp = new Date().getTime();
    console.log(`Making request to /chats?t=${timestamp}`);
    const response = await makeRequest('get', `/chats?t=${timestamp}`);
    console.log('Response received:', response);
    
    // Check if response.data exists and has the expected structure
    if (response.data && response.data.chats && Array.isArray(response.data.chats)) {
      console.log('Returned chats data:', JSON.stringify(response.data, null, 2));
      return {
        chats: response.data.chats,
        subscription: response.data.subscription,
        remainingChats: response.data.remainingChats
      };
    } else {
      console.error('Unexpected response structure:', response);
      return { chats: [], subscription: null, remainingChats: null };
    }
  } catch (error) {
    console.error('Error in fetchChats:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      if (error.response.status === 401) {
        console.log('User not authenticated');
        // You might want to handle this case, e.g., by redirecting to login
      }
    }
    return { chats: [], subscription: null, remainingChats: null };
  }
};

export const addChat = async (title = 'New Chat') => {
  try {
    const response = await makeRequest('post', '/chats', { title });
    return response.data;
  } catch (error) {
    console.error('Error creating new chat:', error);
    throw error;
  }
};

export const removeChat = async (id) => {
  try {
    await makeRequest('delete', `/chats/${id}`);
  } catch (error) {
    console.error('Error removing chat:', error);
    throw error;
  }
};

export const fetchMessages = async (chatId) => {
  try {
    const response = await makeRequest('get', `/chats/${chatId}/messages`);
    return response.data.messages;
  } catch (error) {
    console.error('FetchMessages error:', error.response?.data || error.message);
    throw error;
  }
};

export const editMessage = async (chatId, messageId, content) => {
  try {
    const response = await makeRequest('put', `/chats/${chatId}/messages/${messageId}`, { content });
    return response.data;
  } catch (error) {
    console.error('EditMessage error:', error.response?.data || error.message);
    throw error;
  }
};

export const sendMessage = async (chatId, content, mode, signal, pdfFiles = []) => {
  console.log('Sending message to chat:', chatId, 'Content:', content);
  const formData = new FormData();
  formData.append('content', content);
  formData.append('mode', mode);
  
  if (pdfFiles.length > 0) {
    formData.append('pdf_0', pdfFiles[0].file);
    console.log('Appending PDF file:', pdfFiles[0].file.name);
  }

  try {
    const response = await fetch(`${API_URL}/chats/${chatId}/messages`, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'text/event-stream',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      signal: signal,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    return {
      stream: {
        async *[Symbol.asyncIterator]() {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const chunk = decoder.decode(value);
            const lines = chunk.split('\n');
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                try {
                  const data = JSON.parse(line.slice(5).trim());
                  yield data;
                } catch (error) {
                  console.error('Error parsing SSE data:', error);
                }
              }
            }
          }
        },
      },
      response,
    };
  } catch (error) {
    console.error('Error in sendMessage:', error);
    throw error;
  }
};

export const regenerateMessage = async (chatId, messageId, onChunk, mode) => {
  try {
    const response = await fetch(`${API_URL}/chats/${chatId}/messages/${messageId}/regenerate`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': 'text/event-stream',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mode })
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to regenerate message: ${errorData.message || response.statusText}`);
    }
    
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let regeneratedContent = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          try {
            const data = JSON.parse(line.slice(5).trim());
            if (data.content) {
              regeneratedContent += data.content;
              onChunk(data.content);
            }
          } catch (error) {
            console.error('Error parsing SSE data:', error);
          }
        }
      }
    }

    return { content: regeneratedContent };
  } catch (error) {
    console.error('Error regenerating message:', error);
    throw error;
  }
};

export const createNewChat = async () => {
  try {
    const response = await makeRequest('post', '/chats', { title: 'New Chat' });
    return response.data;
  } catch (error) {
    console.error('Error creating new chat:', error);
    throw error;
  }
};

export const createPDFChat = async (formData) => {
  try {
    const response = await makeRequest('post', '/pdf-chats', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating PDF chat:', error);
    throw error;
  }
};

export const getPDFChats = async () => {
  try {
    const response = await makeRequest('get', '/pdf-chats');
    return response.data;
  } catch (error) {
    console.error('Error fetching PDF chats:', error);
    throw error;
  }
};

export const deletePDFChat = async (chatId) => {
  try {
    const response = await makeRequest('delete', `/pdf-chats/${chatId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting PDF chat:', error);
    throw error;
  }
};

export const getPDFMessages = async (chatId) => {
  try {
    const response = await makeRequest('get', `/pdf-chats/${chatId}/messages`);
    return response.data;
  } catch (error) {
    console.error('Error fetching PDF messages:', error);
    throw error;
  }
};

export const sendPDFMessage = async (chatId, content) => {
  try {
    const response = await makeRequest('post', `/pdf-chats/${chatId}/messages`, { content });
    return response.data;
  } catch (error) {
    console.error('Error sending PDF message:', error);
    throw error;
  }
};

export const sendInitialPDFMessage = async (chatId) => {
  try {
    const response = await makeRequest('post', `/pdf-chats/${chatId}/initial-message`);
    return response.data;
  } catch (error) {
    console.error('Error sending initial PDF message:', error);
    throw error;
  }
};

// ... rest of the existing code ...

export const forgotPassword = async (email) => {
  try {
    const response = await makeRequest('post', '/users/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error('Forgot password error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
}; 
export const sendVerificationEmail = async (email, userData) => {
  try {
    const response = await makeRequest('post', '/users/verify-email', { email, userData });
    return response.data;
  } catch (error) {
    console.error('Verification email error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
};

export const verifyEmailToken = async (token) => {
  try {
    const response = await makeRequest('post', '/users/verify-email-token', { token });
    return response.data;
  } catch (error) {
    console.error('Email verification error:', error);
    throw error;
  }
};

export const createVerifiedUser = async (oneTimeToken, password) => {
  try {
    const response = await makeRequest('post', '/users/create-verified-user', { oneTimeToken, password });
    return response.data;
  } catch (error) {
    console.error('User creation error:', error);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await makeRequest('post', '/users/reset-password', { token, newPassword });
    return response.data;
  } catch (error) {
    console.error('Reset password error:', error.response?.data || error.message);
    throw error;
  }
};

export const sendContactMessage = async (contactData) => {
  try {
    const response = await makeRequest('post', '/contact', contactData);
    return response.data;
  } catch (error) {
    console.error('Error sending contact message:', error.response?.data || error.message);
    throw error;
  }
};

export const sendBetaApplication = async (email, aiUsage, studyHours, feedbackFrequency) => {
  try {
    const response = await makeRequest('post', '/beta/apply', {
      email,
      question1: aiUsage,
      question2: studyHours,
      question3: feedbackFrequency
    });
    return response.data;
  } catch (error) {
    console.error('Beta application error:', error.response?.data || error.message);
    throw error;
  }
};

export const betaSignup = async (name, email, password, token) => {
  try {
    const response = await makeRequest('post', '/beta/signup', {
      name,
      email,
      password,
      token
    });
    return response.data;
  } catch (error) {
    console.error('Beta signup error:', error.response?.data || error.message);
    throw error;
  }
};

export const downloadPDF = async (pdfKey) => {
  try {
    const response = await makeRequest('get', `/pdf/${pdfKey}`, null, {
      responseType: 'blob',
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `document_${pdfKey.split('/').pop()}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw error;
  }
};

// Add this new function to fetch user memory
export const getUserMemory = async () => {
  try {
    const response = await makeRequest('get', '/users/memory');
    return response.data.memoryArray;
  } catch (error) {
    console.error('Error fetching user memory:', error);
    throw error;
  }
};

// Add this new function to update user memory
export const updateUserMemory = async (memoryArray) => {
  try {
    const response = await makeRequest('put', '/users/memory', { memoryArray });
    return response.data;
  } catch (error) {
    console.error('Error updating user memory:', error);
    throw error;
  }
};

export { axiosInstance };