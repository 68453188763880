/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Home.css';
import { useAuth } from '../hooks/useAuth';
import { FaCheck, FaRobot, FaBrain, FaComments, FaQuoteLeft, FaQuoteRight, FaFile, FaHistory, FaMagic } from 'react-icons/fa';
import useDocumentTitle from '../UseDocumentTitle';
import { createCheckoutSession, handleStripePortalRedirect } from '../services/api';
import LoadingOverlay from './Partials/LoadingOverlay';
import { Helmet } from 'react-helmet';

const Home = () => {
    useDocumentTitle('StudyBuddy - AI-Powered Learning | Your Personal Study Assistant for North America');
    const navigate = useNavigate();
    const { isAuthenticated, user, subscription } = useAuth();

    console.log('User subscription:', subscription);

    const features = [
        {
            icon: <FaRobot size={48} className='feature-icons'/>,
            title: "Advanced AI Models",
            description: "Powered by state-of-the-art AI technologies for accurate and helpful responses"
        },
        {
            icon: <FaComments size={48} className='feature-icons'/>,
            title: "Interactive Chat Interface",
            description: "Engage in natural, flowing conversations for an enhanced learning experience"
        },
        {
            icon: <FaFile size={48} className='feature-icons'/>,
            title: "PDF Integration",
            description: "Upload and analyze PDF documents for comprehensive study sessions"
        },
        {
            icon: <FaHistory size={48} className='feature-icons'/>,
            title: "Version Control",
            description: "Track and compare different versions of your study materials"
        },
        {
            icon: <FaMagic size={48} className='feature-icons'/>,
            title: "Smart Text Selection",
            description: "Easily improve or get explanations for specific parts of your study content"
        }
    ];

    const pricingTiers = [
        { 
            name: 'Free', 
            price: { usd: 'Free', cad: 'Free' },
            freeTrial: 'No trial needed',
            features: [
                'Access to core AI capabilities',
                'Daily interaction limits of 5 chats',
            ],
        },
        { 
            name: 'Premium', 
            price: { 
                usd: { monthly: '$5.59', annual: '$49.99' },
                cad: { monthly: '$6.99', annual: '$59.99' }
            },
            freeTrial: '7 days',
            features: [
                'Access to core AI capabilities',
                'Increased interaction limits',
            ]
        },
        { 
            name: 'Pro', 
            price: { 
                usd: { monthly: '$12.99', annual: '$119.99' },
                cad: { monthly: '$15.99', annual: '$139.99' }
            },
            freeTrial: '7 days',
            features: [
                'Everything in Premium, plus:',
                'Unlimited AI interactions',
                'Advanced PDF analysis (3 per chat)',
            ]
        },
        { 
            name: 'Ultimate', 
            price: { 
                usd: { monthly: '$18.99', annual: '$159.99' },
                cad: { monthly: '$21.99', annual: '$189.99' }
            },
            freeTrial: '7 days', 
            features: [
                'Everything in Pro, plus:',
                'Priority response times',
                'Advanced PDF analysis (5 per chat)',
                'Access to beta features',

            ]
        }
    ];

    // Comment out allTestimonials array
    /*
    const allTestimonials = [
        {
            id: 1,
            name: "Sarah L.",
            role: "University Student",
            content: "StudyBuddy has been a game-changer for my academic performance. The AI tutor helps me grasp complex concepts quickly and efficiently."
        },
        {
            id: 2,
            name: "Michael T.",
            role: "High School Senior",
            content: "I've seen a significant improvement in my grades since using StudyBuddy. It's like having a personal tutor available 24/7!"
        },
        {
            id: 3,
            name: "Emily R.",
            role: "Graduate Student",
            content: "The personalized learning experience StudyBuddy offers is unparalleled. It adapts to my pace and learning style perfectly."
        },
        {
            id: 4,
            name: "David K.",
            role: "College Freshman",
            content: "StudyBuddy helped me transition smoothly into college-level coursework. It's an invaluable tool for any student!"
        },
        {
            id: 5,
            name: "Sophia W.",
            role: "High School Junior",
            content: "I love how StudyBuddy breaks down complex topics into easy-to-understand chunks. It's boosted my confidence in all my subjects."
        },
        {
            id: 6,
            name: "Alex M.",
            role: "MBA Student",
            content: "As a busy professional pursuing an MBA, StudyBuddy has been crucial in helping me manage my studies efficiently."
        }
        // Add more testimonials as needed
    ];
    */

    // Comment out displayedTestimonials state and useEffect
    /*
    const [displayedTestimonials, setDisplayedTestimonials] = useState([]);

    useEffect(() => {
        // Function to shuffle array
        const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        // Shuffle and select 3 random testimonials
        const shuffled = shuffleArray([...allTestimonials]);
        setDisplayedTestimonials(shuffled.slice(0, 3));
    }, []); // Empty dependency array means this effect runs once on component mount
    */

    const faqs = [
        {
            id: 1,
            question: "How does StudyBuddy's AI tutor work?",
            answer: "StudyBuddy is an expertly crafted interface that leverages the power of advanced language models. It analyzes your inputs and learning patterns to provide tailored, interactive educational experiences. Think of it as having a highly intelligent tutor available 24/7, ready to assist you with personalized explanations, targeted practice questions, and customized study materials."
        },
        {
            id: 2,
            question: "Is StudyBuddy suitable for all subjects?",
            answer: "Yes! StudyBuddy covers a wide range of subjects including mathematics, sciences, humanities, and languages. Our AI is constantly learning and expanding its knowledge base."
        },
        {
            id: 3,
            question: "Can I use StudyBuddy on multiple devices?",
            answer: "Absolutely! StudyBuddy is accessible on desktop, tablet, and mobile devices. Your progress syncs across all platforms for seamless learning. Mobile app coming!."
        },
        {
            id: 4,
            question: "What payment methods do you accept?",
            answer: "We accept all major credit cards, including Visa, MasterCard, American Express, and Discover."
        },
        {
            id: 5,
            question: "How much does StudyBuddy cost?",
            answer: "We offer flexible pricing plans to suit different needs, ranging from a free tier to premium options. Check our pricing section for current rates. We also offer a free trial for new users on paid plans!"
        },
        {
            id: 6,
            question: "Can I cancel my subscription at any time?",
            answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
        },
        {
            id: 7,
            question: "Is there a refund policy?",
            answer: "We offer a 30-day money-back guarantee for all paid plans. Refunds are dealt with on a case-by-case basis. Please reach out to our support team for assistance."
        },
        {
            id: 8,
            question: "Can I upgrade or downgrade my plan?",
            answer: "Yes, you can change your plan at any time. When upgrading, you'll have immediate access to new features. When downgrading, changes will take effect at the start of your next billing cycle."
        },
        {
            id: 9,
            question: "Is StudyBuddy compatible with both US and Canadian education systems?",
            answer: "Yes, StudyBuddy is designed to support both US and Canadian curricula at all levels: high school, college, and university. Our adaptive learning algorithms ensure personalized education for students in both countries."
        },
        {
            id: 10,
            question: "How does the PDF integration work?",
            answer: "Our PDF integration allows you to upload PDF documents directly into your chat sessions. The AI can then analyze and reference the content of these PDFs to provide more accurate and contextual responses to your questions."
        },
        {
            id: 11,
            question: "What is version control, and how can it help my studies?",
            answer: "Version control allows you to track different versions of your study materials or AI-generated content. This feature helps you compare changes over time, revert to previous versions if needed, and see how your understanding evolves throughout your study sessions."
        },
        {
            id: 12,
            question: "Can I customize the AI's responses to my learning style?",
            answer: "Yes! StudyBuddy offers personalization options where you can input your preferences, learning goals, and study habits. The AI uses this information to tailor its responses and suggestions to better suit your individual learning style."
        }
    ];
    

    const [currency, setCurrency] = useState('usd'); // Default to USD
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [isLoading, setIsLoading] = useState(false);
    const [activeFaqId, setActiveFaqId] = useState(null);

    const toggleFaq = (id) => {
        setActiveFaqId(activeFaqId === id ? null : id);
    };

    useEffect(() => {
        const detectUserLocation = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                if (data.country_code === 'CA') {
                    setCurrency('cad');
                } else if (data.country_code === 'US') {
                    setCurrency('usd');
                } else {
                    setCurrency('usd'); // Default to USD for other countries
                }
            } catch (error) {
                console.error('Error detecting user location:', error);
                setCurrency('usd'); // Default to USD if there's an error
            }
        };

        detectUserLocation();
    }, []);

    const CurrencyAndBillingSelector = () => (
        <div className="pricing-controls">
            <div className="toggle-container">
                <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={billingCycle === 'annual'}
                        onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
                    />
                    <span className="slider round"></span>
                </label>
                <span className={billingCycle === 'annual' ? 'active' : ''}>Annual</span>
            </div>
        </div>
    );

    const handleSubscription = async (tier, interval = 'monthly', currency = 'usd') => {
        if (!isAuthenticated) {
            navigate('/signup', { state: { selectedTier: tier, interval, currency } });
            return;
        }

        try {
            setIsLoading(true);
            if(subscription.tier !== "free"){
                await handleStripePortalRedirect();
                return;
            }
            const response = await createCheckoutSession(tier, interval, currency);
            if (response.url) {
                window.location.href = response.url;
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderButton = (tier) => {
        if (!user) {
            return (
                <button 
                    onClick={() => handleSubscription(tier.name.toLowerCase(), billingCycle, currency)} 
                    className="signup-button"
                >
                    {tier.name.toLowerCase() === 'free' ? 'Get Started' : `Choose ${tier.name}`}
                </button>
            );
        }

        if (subscription && subscription.tier !== 'free') {
            if (subscription.tier.toLowerCase() === tier.name.toLowerCase()) {
                return (
                    <button onClick={handleStripePortalRedirect} className="signup-button">
                        Manage Subscription
                    </button>
                );
            } else {
                return (
                    <button 
                        className="signup-button" 
                        style={{visibility: 'hidden'}} 
                        disabled
                    >
                        Invisible
                    </button>
                );
            }
        }

        // For free users
        return (
            <button 
                onClick={() => handleSubscription(tier.name.toLowerCase(), billingCycle, currency)} 
                className="signup-button"
            >
                {tier.name.toLowerCase() === 'free' ? 'Current Plan' : `Choose ${tier.name}`}
            </button>
        );
    };

    const renderAdButton = () => {
        if (!isAuthenticated) {
            return (
                <Link to="/pricing" className="cta-button primary">
                    Join Ultimate Tier
                </Link>
            );
        }

        if (subscription && subscription.tier === 'ultimate') {
            return (
                <button onClick={handleStripePortalRedirect} className="cta-button primary">
                    Manage Subscription
                </button>
            );
        }

        return (
            <Link 
                to="/pricing" 
                className="cta-button primary"
            >
                Upgrade to Ultimate
            </Link>
        );
    };

    // Updated meta description
    const metaDescription = "StudyBuddy: Your AI-powered study companion for North American students. Elevate your learning with personalized assistance, adaptive intelligence, and 24/7 support across all subjects. Try StudyBuddy free today!";

    // Additional keywords
    const keywords = "StudyBuddy, AI tutor, study assistant, personalized learning, online education, AI-powered learning, academic performance, 24/7 support, adaptive learning, North American education, US students, Canadian students, StuddyBuddy.ca";

    return (
        <div className="home-page">
            <Helmet>
                <title>StudyBuddy - AI-Powered Learning | Your Personal Study Assistant for North America</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={keywords} />
                <link rel="canonical" href="https://studdybuddy.ca" />
                <meta property="og:title" content="StudyBuddy - AI-Powered Learning | Your Personal Study Assistant" />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:url" content="https://studdybuddy.ca" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="StuddyBuddy.ca" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="StudyBuddy - AI-Powered Learning | Your Personal Study Assistant for North America" />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="robots" content="index, follow" />
                <link rel="alternate" hreflang="en-ca" href="https://studdybuddy.ca" />
                <link rel="alternate" hreflang="en-us" href="https://studdybuddy.ca" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "EducationalOrganization",
                        "name": "StudyBuddy",
                        "alternateName": "StuddyBuddy",
                        "description": "${metaDescription}",
                        "url": "https://studdybuddy.ca",
                        "sameAs": [
                            "https://twitter.com/studybuddyai",
                            "https://www.instagram.com/studybuddyai"
                        ],
                        "areaServed": ["CA", "US"],
                        "potentialAction": {
                            "@type": "LearnAction",
                            "target": {
                                "@type": "EntryPoint",
                                "urlTemplate": "https://studdybuddy.ca/signup",
                                "actionPlatform": [
                                    "http://schema.org/DesktopWebPlatform",
                                    "http://schema.org/MobileWebPlatform"
                                ]
                            },
                            "result": {
                                "@type": "LearningResource",
                                "name": "StudyBuddy AI-Powered Learning"
                            }
                        }
                    }
                    `}
                </script>
            </Helmet>
            <LoadingOverlay isLoading={isLoading} />
            <header className="hero">
                <h1>StudyBuddy: Your AI-Powered Learning Companion</h1>
                <p>Elevate your academic performance with personalized, 24/7 AI study assistance across all subjects</p>
                <Link to={isAuthenticated ? "/chats/new" : "pricing"} className="cta-button primary">
                    {isAuthenticated ? "Start Learning Now" : "Begin Your Free StudyBuddy Trial"}
                </Link>
            </header>

            <section id="features" className="features">
                <h2>Revolutionize Your Learning with AI-Powered Features</h2>
                <div className="feature-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-item">
                            {feature.icon}
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section id="pricing" className="pricing">
                <h2>Tailored Plans for Every Student's Success</h2>
                <CurrencyAndBillingSelector />
                <div className="pricing-grid">
                    {pricingTiers.map((tier) => (
                        <div key={tier.name} className={`pricing-item ${tier.name === 'Pro' ? 'featured' : ''}`}>
                            <h3>{tier.name}</h3>
                            {tier.name !== 'Free' ? (
                                <>
                                    <p className="price">
                                        {tier.price[currency][billingCycle]}
                                        <span className="billing-cycle">
                                            {billingCycle === 'monthly' ? '/month' : '/year'}
                                        </span>
                                    </p>
                                    {billingCycle === 'annual' && (
                                        <p className="annual-savings">
                                            Save {(() => {
                                                const monthlyPrice = parseFloat(tier.price[currency].monthly.replace(/[^0-9.]/g, ''));
                                                const annualPrice = parseFloat(tier.price[currency].annual.replace(/[^0-9.]/g, ''));
                                                return ((monthlyPrice * 12 - annualPrice) / (monthlyPrice * 12) * 100).toFixed(0);
                                            })()}%
                                        </p>
                                    )}
                                </>
                            ) : (
                                <p className="price">{tier.price[currency]}</p>
                            )}
                            <p className="free-trial" style={{ visibility: tier.name === 'Free' ? 'hidden' : 'visible' }}>
                                {tier.freeTrial} free trial
                            </p>
                            <ul className="feature-list">
                                {tier.features.map((feature, index) => (
                                    <li key={index}><FaCheck className="feature-check" /> {feature}</li>
                                ))}
                            </ul>
                            {renderButton(tier)}
                        </div>
                    ))}
                </div>

                <Link to="/features" className="cta-button secondary">
                        Learn More
                </Link>
            </section>
            {/*}

            <section className="smart-ad">
                <div className="ad-content">
                    <h2>Exclusive Beta Features for Ultimate Tier Members</h2>
                    <div className="chat-preview">
                        <div className="pdf-preview">
                            <div className="pdf-icon">📄</div>
                            <p>Your PDF Document</p>
                        </div>
                        <div className="chat-messages">
                            <div className="message user-message edit-message">
                                <p>Can you explain the concept on page 3?</p>
                                <span className="edit-indicator">✏️ Edited</span>
                            </div>
                            <div className="message ai-message">
                                <p>Certainly! The concept on page 3 discusses the principles of thermodynamics. It explains how energy is transferred between different forms and systems.</p>
                            </div>
                            <div className="message user-message">
                                <p>That's helpful, thanks! Can you give me an example of how this applies in real life?</p>
                            </div>
                        </div>
                    </div>
                    <p className="ad-cta">Be among the first to experience PDF integration and message editing!</p>
                    <div className="rollout-info">
                        <p>🚀 Gradual rollout starting this weekend</p>
                        <p>🔓 Exclusive early access for Ultimate users</p>
                    </div>
                    {renderAdButton()}
                </div>
            </section>

            {*/}

            {/* Comment out the AITutorPreview section */}
            {/*
            <Suspense fallback={<div>Loading...</div>}>
                <AITutorPreview />
            </Suspense>
            */}

            {/* Comment out the testimonials section */}
            {/*
            <section className="testimonials">
                <h2>What Our Students Say</h2>
                <div className="testimonial-grid">
                    {displayedTestimonials.map((testimonial) => (
                        <div key={testimonial.id} className="testimonial-card">
                            <FaQuoteLeft className="quote-icon" />
                            <p className="testimonial-content">{testimonial.content}</p>
                            <FaQuoteRight className="quote-icon" />
                            <p className="testimonial-author">- {testimonial.name}</p>
                            <p className="testimonial-role">{testimonial.role}</p>
                        </div>
                    ))}
                </div>
            </section>
            */}

            <section className="faq">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-grid">
                    {faqs.map((faq) => (
                        <div 
                            key={faq.id} 
                            className={`faq-item ${activeFaqId === faq.id ? 'active' : ''}`}
                            onClick={() => toggleFaq(faq.id)}
                        >
                            <h3 className="faq-question">{faq.question}</h3>
                            <p className="faq-answer">{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section className="why-choose-us">
                <h2>Why Choose StudyBuddy?</h2>
                <ul>
                    <li>Tailored for North American education systems</li>
                    <li>Supports US and Canadian curricula at all levels: high school, college, and university</li>
                    <li>Comprehensive subject coverage: from STEM to humanities</li>
                    <li>Adaptive learning algorithms for personalized education</li>
                    <li>Secure, privacy-focused platform compliant with North American standards</li>
                    <li>Flexible pricing in both USD and CAD</li>
                </ul>
            </section>

            <section className="cta-section">
                <h2>Ready to Transform Your Learning Experience?</h2>
                <p>Join thousands of US and Canadian students already benefiting from StudyBuddy</p>
                <Link to={isAuthenticated ? "/chats/new" : "/signup"} className="cta-button primary">
                    {isAuthenticated ? "Start Learning Now" : "Begin Your Free Trial Today"}
                </Link>
            </section>

        </div>
    );
};

export default Home;