/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { updateProfile, deleteAccount, handleStripePortalRedirect, getProfile, updateUserMemory } from '../services/api';
import '../Styles/Settings.css';
import '../Styles/LoadingOverlay.css';
import { FaUser, FaCreditCard, FaShieldAlt, FaPalette, FaGlobe, FaTrash, FaToggleOn, FaToggleOff, FaSpinner } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import ChangePassword from './Auth/ChangePassword';
import Modal from './Partials/Modal'; // You'll need to create this component

const Settings = () => {
  const navigate = useNavigate();
  const { user, logout, checkUser } = useAuth();
  const [activeSection, setActiveSection] = useState('profile');
  const [settings, setSettings] = useState({
    twoFactorEnabled: false,
    fontSize: 'medium',
    reduceMotion: false,
    timeZone: 'UTC'
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [profileData, setProfileData] = useState({
    name: user?.name || '',
    phone_number: user?.phone_number || '',
  });
  const [defaultChatModel, setDefaultChatModel] = useState(() => {
    return localStorage.getItem('defaultChatModel') || 'qa';
  });
  const [fontSize, setFontSize] = useState(() => {
    return localStorage.getItem('fontSize') || 'medium';
  });
  const [memoryArray, setMemoryArray] = useState([]);

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const fetchAllData = async () => {
    setIsLoading(true);
    try {
      const userData = await getProfile();
      setProfileData({
        name: userData.name || '',
        phone_number: userData.phone_number || '',
      });
      setMemoryArray(userData.memoryArray || []);
      setIsLoading(false);
    } catch (error) {
      setError('Failed to fetch user data');
      setIsLoading(false);
    }
  };

  const handleSettingChange = (key, value) => {
    setSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      await updateProfile(profileData);
      setSuccessMessage('Profile updated successfully');
      // Update the user context if necessary
      // For example: updateUser({ ...user, name: profileData.name, phone_number: profileData.phone_number });
    } catch (error) {
      setError('Failed to update profile: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      setIsLoading(true);
      try {
        await deleteAccount();
        logout();
        window.location.href = '/';
      } catch (error) {
        setError('Failed to delete account: ' + (error.response?.data?.message || error.message));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDefaultChatModelChange = (e) => {
    const newModel = e.target.value;
    setDefaultChatModel(newModel);
    localStorage.setItem('defaultChatModel', newModel);
  };

  const handleFontSizeChange = (e) => {
    const newSize = e.target.value;
    setFontSize(newSize);
    localStorage.setItem('fontSize', newSize);
    document.documentElement.style.fontSize = getFontSizeValue(newSize);
  };

  const getFontSizeValue = (size) => {
    switch (size) {
      case 'x-small':
        return '12px';
      case 'small':
        return '14px';
      case 'medium':
        return '16px';
      case 'large':
        return '18px';
      case 'x-large':
        return '20px';
      default:
        return '16px'; // medium
    }
  };

  useEffect(() => {
    document.documentElement.style.fontSize = getFontSizeValue(fontSize);
  }, [fontSize]);

  const handleMemoryChange = (index, value) => {
    const updatedMemory = [...memoryArray];
    updatedMemory[index] = value;
    setMemoryArray(updatedMemory);
  };

  const handleAddMemory = () => {
    setMemoryArray([...memoryArray, '']);
  };

  const handleRemoveMemory = (index) => {
    const updatedMemory = memoryArray.filter((_, i) => i !== index);
    setMemoryArray(updatedMemory);
  };

  const handleMemorySubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      await updateUserMemory(memoryArray);
      setSuccessMessage('Memory updated successfully');
    } catch (error) {
      setError('Failed to update memory: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const renderProfileSection = () => (
    <div className="settings-section">
      <h2>Profile Information</h2>
      <form onSubmit={handleProfileSubmit} className="settings-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={profileData.name}
            onChange={handleProfileChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email (cannot be changed)</label>
          <input
            type="email"
            id="email"
            name="email"
            value={user?.email || ''}
            disabled
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone_number">Phone Number</label>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            value={profileData.phone_number}
            onChange={handleProfileChange}
          />
        </div>
        <button type="submit" className="submit-button">Update Profile</button>
      </form>
    </div>
  );

  const renderAccountSection = () => (
    <div className="settings-section">
      <h2>Account Settings</h2>
      <div className="subscription-info">
        <p><strong>Current Plan:</strong> {user?.tier?.toUpperCase() || 'N/A'}</p>
        <button onClick={handleStripePortalRedirect} className="submit-button">
          Manage Subscription
        </button>
      </div>
      <div className="security-settings">
        <h3>Security</h3>
        <button onClick={() => setShowChangePassword(true)} className="submit-button">Change Password</button>
      </div>
    </div>
  );

  const renderPreferencesSection = () => (
    <div className="settings-section">
      <h2>Preferences</h2>
      <div className="form-group">
        <label htmlFor="fontSize">Font Size</label>
        <select 
          id="fontSize" 
          value={fontSize}
          onChange={handleFontSizeChange}
        >
          <option value="x-small">Extra Small</option>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
          <option value="x-large">Extra Large</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="defaultChatModel">Default Chat Mode</label>
        <select 
          id="defaultChatModel" 
          value={defaultChatModel}
          onChange={handleDefaultChatModelChange}
        >
          <option value="qa">Q&A</option>
          <option value="tutor">Tutor</option>
          <option value="brainstorm">Brainstorm</option>
          <option value="debate">Debate</option>
        </select>
      </div>
      {/* <h3>Memory</h3>
      <form onSubmit={handleMemorySubmit}>
        {memoryArray.map((memory, index) => (
          <div key={index} className="memory-item">
            <input
              type="text"
              value={memory}
              onChange={(e) => handleMemoryChange(index, e.target.value)}
              placeholder="Enter a memory or preference"
            />
            <button type="button" onClick={() => handleRemoveMemory(index)}>Remove</button>
          </div>
        ))}
        <button type="button" onClick={handleAddMemory}>Add Memory</button>
        <button type="submit">Save Memory</button>
      </form> */}
    </div>
  );

  const renderDeleteAccountSection = () => (
    <div className="settings-section">
      <h2>Delete Account</h2>
      <p>Warning: This action cannot be undone. All your data will be permanently deleted.</p>
      <button onClick={handleDeleteAccount} className="delete-account-button">
        Delete My Account
      </button>
    </div>
  );

  const sections = [
    { id: 'profile', icon: FaUser, label: 'Profile', render: renderProfileSection },
    { id: 'account', icon: FaCreditCard, label: 'Account', render: renderAccountSection },
    // { id: 'preferences', icon: FaPalette, label: 'Preferences', render: renderPreferencesSection },
    { id: 'preferences', icon: FaPalette, label: 'Preferences', render: renderPreferencesSection },
    { id: 'delete', icon: FaTrash, label: 'Delete Account', render: renderDeleteAccountSection },

  ];

  const renderLoadingOverlay = () => (
    <div className="loading-overlay">
      <div className="loading-content">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );

  return (
    <div className="settings-page">
      <header className="settings-header">
        <h1>Settings</h1>
        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </header>
      <div className="settings-content">
        <nav className="settings-nav">
          {sections.map((section) => (
            <button
              key={section.id}
              className={`nav-item ${activeSection === section.id ? 'active' : ''}`}
              onClick={() => setActiveSection(section.id)}
            >
              <section.icon />
              <span>{section.label}</span>
            </button>
          ))}
        </nav>
        <main className="settings-main">
          {isLoading ? renderLoadingOverlay() : (
            sections.find(section => section.id === activeSection)?.render()
          )}
        </main>
      </div>
      {showChangePassword && (
        <Modal onClose={() => setShowChangePassword(false)}>
          <ChangePassword onClose={() => setShowChangePassword(false)} />
        </Modal>
      )}
    </div>
  );
};

export default Settings;