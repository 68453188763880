/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// Add this near the top of your file
// eslint-disable-next-line react/no-deprecated


import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams, Navigate, Switch } from 'react-router-dom';
import SignUp from './Components/Auth/Signup';
import Login from './Components/Auth/Login.jsx';
import Settings from './Components/Settings.jsx';
import PrivateRoute from './Components/Auth/PrivateRoute';
import Chats from './Components/Chats';
import { AuthProvider, useAuth } from './hooks/useAuth';
import './Styles/GlobalStyles.css';
import './App.css';
import { CssBaseline, Container} from '@mui/material';
import Home from './Components/Home';
import DevUpdates from './Components/Info Pages/DevUpdates';
import Features from './Components/Features';
import ContactUs from './Components/ContactUs';
import PrivacyPolicy from './Components/Info Pages/PrivacyPolicy'
import Footer from './Components/Partials/Footer';
import Pricing from './Components/Pricing';
import NavBar from './Components/Partials/NavBar.jsx';
import NotFound from './Components/Info Pages/404';
import ChatWithPDF from './Components/ChatWIthPDF.jsx'
import LearningOptions from './Components/LearningOptions';
import LoadingOverlay from './Components/Partials/LoadingOverlay';
import { QueryClient, QueryClientProvider } from 'react-query';
import TermsofService from './Components/Info Pages/TermsofService.jsx';
import SitewideBanner from './Components/Partials/SitewideBanner'; // Add this import
import { useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Styles/Toast.css';  // Add this import
import PaymentConfirmation from './Components/PaymentConfirmation';
import BetaSignup from './Components/Auth/BetaSignup';
import { fetchChats } from './services/api';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import VerifyEmail from './Components/Auth/VerifyEmail';
import BetaLanding from './Components/BetaLanding';
const queryClient = new QueryClient();

const PublicRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? <Navigate to="/" replace /> : children;
};

const AppContent = () => {
  const { isLoading, globalLoading, user, subscription } = useAuth();
  const location = useLocation();
  const isChatsPage = /^\/chats/.test(location.pathname);
  const isLandingPage = /^\/$/.test(location.pathname);
  const isPDFPage = /^\/chatwithpdf/.test(location.pathname);
  const isSignupPage = /^\/signup/.test(location.pathname);
  const isLoginPage = /^\/login/.test(location.pathname);
  const isResetPasswordPage = /^\/reset-password/.test(location.pathname);
  const isForgotPasswordPage = /^\/forgot-password/.test(location.pathname);
  const isVerifyEmailPage = /^\/verify-email/.test(location.pathname);
  const showNavBar =  !isChatsPage && !isPDFPage && !isSignupPage && !isLoginPage && !isResetPasswordPage && !isForgotPasswordPage && !isVerifyEmailPage;
  const showFooter = !isChatsPage && !isPDFPage && !isSignupPage && !isLoginPage && !isResetPasswordPage && !isForgotPasswordPage && !isVerifyEmailPage;

  // Add this query for fetching chats
  const { data: chatsData, isLoading: isChatsLoading } = useQuery(
    'chats',
    fetchChats,
    {
      enabled: !!user,
      staleTime: 5 * 60 * 1000, // 5 minutes
      onError: (error) => console.error('Error fetching chats data:', error)
    }
  );

  const chats = chatsData?.chats || [];
  const remainingChats = chatsData?.remainingChats;

  // Add this function to sort chats
  const sortedChats = React.useMemo(() => {
    return [...chats].sort((a, b) => new Date(b.last_modifield) - new Date(a.last_modifield));
  }, [chats]);

  if (isLoading || globalLoading) {
    return <LoadingOverlay />;
  }

  return (
    <div className="app-container">

      <main className="content">
        <CssBaseline />
        <Container maxWidth disableGutters>
          {showNavBar && <NavBar />}
          <Routes>
            <Route 
              path="/chats/:chatId" 
              element={
                <PrivateRoute>
                  <Chats 
                    subscription={subscription}
                    remainingChats={remainingChats}
                  />
                </PrivateRoute>
              } 
            />
            {/* <Route path="/chatwithpdf" element={<ChatWithPDF />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/beta" element={<BetaLanding />} />
            <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/beta-signup" element={<BetaSignup />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/pricing/:tierName" element={<Pricing />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/dev-updates" element={<DevUpdates />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsofService />} />
            <Route path="/features" element={<Features />} />
            <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
            <Route path="/reset-password" element={<PublicRoute><ResetPassword /></PublicRoute>} />
            <Route path="/verify-email" element={<PublicRoute><VerifyEmail /></PublicRoute>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </Container>
      </main>
      {showFooter && <Footer />}
    </div>
  );
};

const App = () => {
  useEffect(() => {
    const getFontSizeValue = (size) => {
      switch (size) {
        case 'x-small':
          return '12px';
        case 'small':
          return '14px';
        case 'medium':
          return '16px';
        case 'large':
          return '18px';
        case 'x-large':
          return '20px';
        default:
          return '16px'; // medium
      }
    };

    const setFontSize = () => {
      const fontSize = localStorage.getItem('fontSize') || 'medium';
      document.documentElement.style.fontSize = getFontSizeValue(fontSize);
    };

    setFontSize();

    window.addEventListener('storage', setFontSize);

    return () => {
      window.removeEventListener('storage', setFontSize);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <AppContent />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
