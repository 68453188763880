/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Send, Menu, Settings, Home, ChevronDown, Copy, Edit, StopCircle, Book, HelpCircle, Lightbulb,  MessageSquare, Check, GitCommit, RefreshCw, Download, Paperclip, X, Globe, Sliders } from 'lucide-react'; // Added X icon and Globe icon
import Sidebar from './Partials/Sidebar';
import '../Styles/Chat.css';
import '../Styles/katex.css';
import '../Styles/TextRenderer.css';
import ResourcesSection from './Partials/Resources';
import { fetchMessages, sendMessage, createNewChat, editMessage, regenerateMessage, downloadPDF } from '../services/api';
import { shuffle } from 'lodash';
import { categorizedSuggestions } from './Data/AllSuggestions';
import { MarkdownRenderer, AIResponseRenderer } from './Partials/LLMTextRenderer';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Toast from './Partials/Toast';
import useDocumentTitle from '../UseDocumentTitle';
import { useAuth } from '../hooks/useAuth';
import { Helmet } from 'react-helmet';
import studyBuddyLogo from '../assets/Empty High-Res Logo.png';
import { FileText } from 'react-feather';
import { fetchChats } from '../services/api';
import { FaChevronRight } from 'react-icons/fa'
import { Code, File } from 'lucide-react';
import ExpandedResource from './Partials/ExpandedResource';
import SalesModal from './Partials/SalesModal';
import CognoraLogo from '../assets/Cognora Logo High Res.png';

const Chats = ({ subscription, remainingChats: initialRemainingChats }) => {
  const { user } = useAuth();
  useDocumentTitle('Chats - StudyBuddy');
  const [remainingChats, setRemainingChats] = useState(initialRemainingChats);
  const queryClient = useQueryClient();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const inputRef = useRef('');
  const textareaRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [isResourcesVisible, setIsResourcesVisible] = useState(() => {
    return !isMobile && chatId !== 'new' && chatId !== undefined;
  });
  const [isResourcesExpanded, setIsResourcesExpanded] = useState(false);
  const [expandedResource, setExpandedResource] = useState(null);
  const [expandedVersion, setExpandedVersion] = useState(-1);


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [chatIdToUse, setChatIdToUse] = useState(null);
  const [currentChatTitle, setCurrentChatTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInputProcessing, setIsInputProcessing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesContainerRef = useRef(null);
  const [isSendingFirstMessage, setIsSendingFirstMessage] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  
  // New state for editing and branching
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [activeBranchIndex, setActiveBranchIndex] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const abortControllerRef = useRef(null);
  const [partialResponse, setPartialResponse] = useState('');
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const lastMessageRef = useRef(null);
  const [isDefaultDisplay, setIsDefaultDisplay] = useState(false);

  const [currentMode, setCurrentMode] = useState(() => {
    return localStorage.getItem('defaultChatModel') || 'qa';
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  const [filteredMessages, setFilteredMessages] = useState([]);

  const [abortController, setAbortController] = useState(null);

  // Add these new state variables
  const [regeneratingMessageId, setRegeneratingMessageId] = useState(null);
  const [regeneratedContent, setRegeneratedContent] = useState('');

  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const [generatedContent, setGeneratedContent] = useState('');

  // Add these new state variables
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [showToast, setShowToast] = useState(false);

  // Change this line to allow only one PDF file
  const [pdfFile, setPdfFile] = useState(null);

  const fileInputRef = useRef(null);

  // Add this new state
  const [lastMessageUsedWeb, setLastMessageUsedWeb] = useState(false);

  // Add this function to show custom toast
  const showCustomToast = (type, message) => {
    setToastType(type);
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000); // Hide after 3 seconds
  };

  // New state for parsed PDF content
  const [parsedPDFContent, setParsedPDFContent] = useState(null);

  // Update the resources state to be an object with chatId as keys
  const [resources, setResources] = useState({});

  // New state variables for smart scrolling
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  // Ref to track processed messages
  const processedMessageIdsRef = useRef(new Set());

  const [showSalesModal, setShowSalesModal] = useState(false);
  const [salesModalFeature, setSalesModalFeature] = useState('');
  const [pdfCount, setPdfCount] = useState(0);

  const closeSalesModal = () => {
    setShowSalesModal(false);
    setSalesModalFeature('');
  };

  useEffect(() => {
    if(filteredMessages.length === 0) {
      setIsDefaultDisplay(true);
    } else {
      setIsDefaultDisplay(false);
    }
  }, [filteredMessages]);


  // Initialize suggestions
  useEffect(() => {
    const randomCategories = shuffle(categorizedSuggestions).slice(0, 3);
    const randomSuggestions = randomCategories.map(category => ({
      icon: category.icon,
      text: shuffle(category.suggestions)[0]
    }));
    setSuggestions(randomSuggestions);
  }, []);

  // Update remaining chats when initialRemainingChats changes
  useEffect(() => {
    setRemainingChats(initialRemainingChats);
  }, [initialRemainingChats]);

  // Log remaining chats for debugging
  useEffect(() => {
    console.log('remainingChats:', remainingChats);
  }, [remainingChats]);  

  // Update the scrollToBottom function
  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100); // Add a small delay to ensure the new content is rendered
    }
    setShowScrollButton(false);
  }, []);

  // Fetch messages using react-query
  const { data: messagesData, isLoading: isMessagesLoading } = useQuery(
    ['messages', chatId],
    () => fetchMessages(chatId),
    {
      enabled: !!chatId && chatId !== 'new',
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
      onSuccess: (data) => {
        setMessages(data);
        try {
          localStorage.setItem(`chat_${chatId}`, JSON.stringify(data));
        } catch (error) {
          console.error('Error storing messages in localStorage:', error);
        }
      },
    }
  );

  // Mutation for editing messages
  const editMessageMutation = useMutation(
    ({ messageId, content }) => editMessage(chatId, messageId, content),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['messages', chatId]);
      },
    }
  );

  // Add this new mutation
  const regenerateMessageMutation = useMutation(
    ({ messageId }) => regenerateMessage(chatId, messageId, () => {}, currentMode),
    {
      onSuccess: async () => {
        // This will be empty now, as we're handling the streaming in the component
      },
      onError: (error) => {
        console.error('Error regenerating message:', error);
        showCustomToast('error', 'Failed to regenerate message. Please try again.');
        setRegeneratingMessageId(null);
      },
    }
  );

  // Start editing a message
  const handleEditStart = useCallback((message) => {
    setEditingMessageId(message.id);
    setEditContent(message.content);
  }, []);

  // Cancel editing a message
  const handleEditCancel = useCallback(() => {
    setEditingMessageId(null);
    setEditContent('');
  }, []);

  // Submit edited message
  const handleEditSubmit = useCallback((messageId) => {
    editMessageMutation.mutate({ messageId, content: editContent });
    setEditingMessageId(null);
    setEditContent('');
  }, [editContent, editMessageMutation]);

  const handleRegenerateMessage = useCallback((messageId) => {
    setRegeneratingMessageId(messageId);
    setRegeneratedContent('');
    setIsGeneratingResponse(true);
    
    // Store the removed messages
    let removedMessages = [];

    // Remove the current message and all messages after it
    setMessages(prevMessages => {
      const messageIndex = prevMessages.findIndex(m => m.id === messageId);
      removedMessages = prevMessages.slice(messageIndex);
      return prevMessages.slice(0, messageIndex);
    });

    // Remove associated resources
    setResources(prevResources => {
      const updatedResources = { ...prevResources };
      if (updatedResources[chatId]) {
        updatedResources[chatId] = updatedResources[chatId].filter(resource => 
          !removedMessages.some(msg => msg.content.includes(resource.id))
        );
      }
      return updatedResources;
    });

    regenerateMessageMutation.mutate({ messageId }, {
      onSuccess: async () => {
        try {
          let accumulatedContent = '';
          let chunkToDisplay = '';
          await regenerateMessage(chatId, messageId, (chunk) => {
            let processedChunk = chunk;

            if (accumulatedContent.includes('<artifacts>')) {
              if (!accumulatedContent.includes('</artifacts>')) {
                accumulatedContent += processedChunk;
              } else {
                accumulatedContent += processedChunk;
                chunkToDisplay += processedChunk;
              }
            } else {
              accumulatedContent += processedChunk;
              chunkToDisplay += processedChunk;
            }

            setGeneratedContent(chunkToDisplay);
            
            // Scroll to bottom as new content comes in
            scrollToBottom();
          });
        // Update the messages state with the streaming content
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          const lastMessage = updatedMessages[updatedMessages.length - 1];
          if (lastMessage && lastMessage.id === messageId) {
            lastMessage.content = accumulatedContent;
          } else {
            updatedMessages.push({
              id: messageId,
              content: accumulatedContent,
              is_ai: true,
              timestamp: new Date().toISOString(),
            });
          }
          return updatedMessages;
        });

          // After streaming is complete, process artifacts
          processArtifacts(accumulatedContent);

          // Clear the regenerating message ID
          setRegeneratingMessageId(null);

          // Invalidate and refetch the messages query to ensure consistency
          queryClient.invalidateQueries(['messages', chatId]);
        } catch (error) {
          console.error('Error processing regenerated message:', error);
          showCustomToast('error', 'Failed to process regenerated message. Please try again.');
          setRegeneratingMessageId(null);
          // Return the initially removed messages and resources
          setMessages(prevMessages => [...prevMessages, ...removedMessages]);
          setResources(prevResources => {
            const updatedResources = { ...prevResources };
            if (updatedResources[chatId]) {
              updatedResources[chatId] = [
                ...updatedResources[chatId],
                ...removedMessages.flatMap(msg => {
                  const resourceIds = msg.content.match(/id="(resource-[^"]+)"/g);
                  return resourceIds ? resourceIds.map(id => ({ id: id.slice(4, -1) })) : [];
                })
              ];
            }
            return updatedResources;
          });
        } finally {
          setIsGeneratingResponse(false);
        }
      },
      onError: (error) => {
        console.error('Error regenerating message:', error);
        showCustomToast('error', 'Failed to regenerate message. Please try again.');
        setRegeneratingMessageId(null);
        setIsGeneratingResponse(false);
        // Return the initially removed messages and resources
        setMessages(prevMessages => [...prevMessages, ...removedMessages]);
        setResources(prevResources => {
          const updatedResources = { ...prevResources };
          if (updatedResources[chatId]) {
            updatedResources[chatId] = [
              ...updatedResources[chatId],
              ...removedMessages.flatMap(msg => {
                const resourceIds = msg.content.match(/id="(resource-[^"]+)"/g);
                return resourceIds ? resourceIds.map(id => ({ id: id.slice(4, -1) })) : [];
              })
            ];
          }
          return updatedResources;
        });
      },
    });
  }, [regenerateMessageMutation, chatId, queryClient, scrollToBottom, showCustomToast, currentMode]);

  // Toggle message branch
  const toggleBranch = useCallback((messageId) => {
    setActiveBranchIndex(prev => ({
      ...prev,
      [messageId]: ((prev[messageId] || 0) + 1) % (messages.find(m => m.id === messageId)?.edit_count + 1 || 1)
    }));
  }, [messages]);

  // Group messages for handling branches
  const groupedMessages = useMemo(() => {
    if (!messages) return [];
    const groups = {};
    messages.forEach(message => {
      if (message.parent_message_id) {
        if (!groups[message.parent_message_id]) {
          groups[message.parent_message_id] = [];
        }
        groups[message.parent_message_id].push(message);
      } else {
        if (!groups[message.id]) {
          groups[message.id] = [message];
        }
      }
    });
    return Object.values(groups);
  }, [messages]);

  // Handle search input
  const handleSearch = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  // Add a new chat
  const handleAddChat = async () => {
    try {
      navigate(`/chats/new`);
      // Additional logic if needed
    } catch (error) {
      console.error('Error creating new chat:', error);
      showCustomToast('error', 'Failed to create a new chat. Please try again.');
    }
  };
    // Update the handleCreateResource function
    const handleCreateResource = useCallback((newResource) => {
      setResources(prevResources => ({
        ...prevResources,
        [chatId]: [...(prevResources[chatId] || []), newResource]
      }));
    }, [chatId]);

  // Load messages from cache or fetch new ones
  useEffect(() => {
    if (chatId && chatId !== 'new') {
      const cachedMessages = localStorage.getItem(`chat_${chatId}`);
      if (cachedMessages && cachedMessages !== 'undefined') { // Added check
        try {
          const parsedMessages = JSON.parse(cachedMessages);
          setMessages(parsedMessages);
        } catch (error) {
          console.error('Error parsing cached messages:', error);
          // Optionally, remove the corrupted cache
          localStorage.removeItem(`chat_${chatId}`);
          // Fallback to messagesData or an empty array
          setMessages(messagesData || []);
        }
      } else if (messagesData) {
        setMessages(messagesData);
      }
    } else if (chatId === 'new') {
      setMessages([]);
    }
  }, [chatId, messagesData]);

  // Auto-scroll to the latest message
  useEffect(() => {
    if (autoScroll && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages, partialResponse, autoScroll]);

  // Update the useEffect for window resize
  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
      
      // Only update sidebar state if transitioning between mobile and desktop
      if (newIsMobile !== isMobile) {
        setIsSidebarOpen(!newIsMobile);
      }
      
      // Adjust resources visibility only when transitioning to mobile
      if (newIsMobile && !isMobile) {
        setIsResourcesVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  // Update the handleUpdateTitle function
  const handleUpdateTitle = useCallback((newTitle) => {
    console.log('handleUpdateTitle called', { newTitle });
    setCurrentChatTitle(newTitle);
  }, []);

  // Update chatIdToUse based on chatId
  useEffect(() => {
    if (chatId === 'new') {
      setChatIdToUse(null);
    } else if (chatId) {
      setChatIdToUse(chatId);
    }
  }, [chatId]);

const handleImprove = (message) => {
  setInput(message);
  handleSubmit({ preventDefault: () => {} });
};

const handleExplain = (message) => {
  console.log('handleExplain called with message:', message);
  setInput(message);
  console.log('Input set to:', message);
  handleSubmit({ preventDefault: () => {} });
  console.log('handleSubmit called');
};

  // Update the processArtifacts function
  const processArtifacts = useCallback((artifactContent) => {
    console.log('Starting processArtifacts function');
    let processedContent = artifactContent;

    // Parse artifacts
    const artifactsMatch = artifactContent.match(/<artifacts>([\s\S]*?)<\/artifacts>/g);
    console.log('Artifacts found:', artifactsMatch ? artifactsMatch.length : 0);
    
    if (artifactsMatch) {
      artifactsMatch.forEach((artifact, index) => {
        console.log(`Processing artifact ${index + 1}`);

        // Parse essay artifacts
        const essayMatch = artifact.match(/<essay>([\s\S]*?)<\/essay>/);
        if (essayMatch) {
          console.log('Essay artifact found');
          const essayContent = essayMatch[1];
          const titleMatch = essayContent.match(/<title>(.*?)<\/title>/);
          const contentMatch = essayContent.match(/<content>([\s\S]*?)<\/content>/);
          
          if (titleMatch && contentMatch) {
            console.log('Essay title and content found');
            const title = titleMatch[1];
            const existingResources = resources[chatId] || [];
            const sameTypeResources = existingResources.filter(r => r.type === 'essay' && r.title === title);
            const version = sameTypeResources.length + 1;
            
            const resourceId = `essay-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'essay',
              title: titleMatch[1],
              content: contentMatch[1],
              version: version,
              timestamp: new Date().toISOString()
            };
            console.log('Creating new essay resource:', newResource);
            handleCreateResource(newResource);
          } else {
            console.log('Essay title or content not found');
          }
        }

        // Parse code artifacts
        const codeMatch = artifact.match(/<code.*?>([\s\S]*?)<\/code>/);
        if (codeMatch) {
          console.log('Code artifact found');
          const codeContent = codeMatch[1];
          const languageMatch = codeMatch[0].match(/language="(.*?)"/);
          const titleMatch = codeMatch[0].match(/title="(.*?)"/);
          
          if (languageMatch && titleMatch) {
            console.log('Code language and title found');
            const title = titleMatch[1];
            const existingResources = resources[chatId] || [];
            const sameTypeResources = existingResources.filter(r => r.type === 'code' && r.title === title);
            const version = sameTypeResources.length + 1;
            
            const resourceId = `code-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'code',
              language: languageMatch[1],
              title: title,
              content: codeContent,
              version: version,
              timestamp: new Date().toISOString()
            };
            console.log('Creating new code resource:', newResource);
            handleCreateResource(newResource);
          } else {
            console.log('Code language or title not found');
          }
        }
      });
    } else {
      console.log('No artifacts found in the content');
    }

    console.log('Finished processing artifacts' + resources);
    return processedContent;
  }, [handleCreateResource]);



  const handleResourceClick = (resourceTitle, version) => {
    if (!resources || !resources[chatId] || !Array.isArray(resources[chatId])) {
      console.error('Resources are not available or not in the expected format');
      return;
    }

    const foundResources = resources[chatId].filter(resource => resource.title === resourceTitle);
    
    if (foundResources.length === 0) {
      console.error('Resource not found:', resourceTitle);
      return;
    }

    let selectedResource;

    if (version) {
      // If a specific version is requested, find that version
      selectedResource = foundResources.find(resource => resource.version === version);
      if (!selectedResource) {
        console.error('Specified version not found:', version);
        // Fallback to the latest version if the specified version is not found
        selectedResource = foundResources.reduce((latest, current) => 
          (current.version > latest.version) ? current : latest
        );
      }
    } else {
      // If no version is specified, use the latest version
      selectedResource = foundResources.reduce((latest, current) => 
        (current.version > latest.version) ? current : latest
      );
    }

    setIsResourcesVisible(true);
    setIsResourcesExpanded(true);
    if (isResourcesVisible && !isResourcesExpanded) {
      setIsResourcesExpanded(true);
    } 

    console.log('selectedResource', selectedResource.title, 'version', selectedResource.version);
    setExpandedVersion(selectedResource.version);
    setExpandedResource(selectedResource);
  };

  // Fetch chats using react-query
  const { data: chatsData } = useQuery('chats', fetchChats, {
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });


  useEffect(() => {
    if (chatsData && chatsData.chats && Array.isArray(chatsData.chats)) {
      const newResources = chatsData.chats.reduce((acc, chat) => {
        if (chat && chat.pdf_content) {
          // Split the pdf_content by <PDF START> to separate multiple PDFs
          const pdfSections = chat.pdf_content.split('<PDF START>');
          
          // Process each PDF section
          pdfSections.forEach((section, index) => {
            if (index === 0) return; // Skip the first empty split

            const titleMatch = section.match(/\[PDF TITLE: (.*?)\]/);
            const title = titleMatch ? titleMatch[1].trim() : `Untitled PDF ${index}`;
            
            const linkMatch = section.match(/\[LINK: (.*?)\]/);
            const link = linkMatch ? linkMatch[1] : null;

            const contentStart = section.indexOf('\n', section.indexOf('\n') + 1);
            const contentEnd = section.indexOf('<PDF END>');
            let content = contentStart !== -1 && contentEnd !== -1 
              ? section.slice(contentStart, contentEnd).trim()
              : section.trim();

            // Remove [LINK: ...] from the content
            content = content.replace(/\[LINK:.*?\]/g, '').trim();

            if (!acc[chat.id]) {
              acc[chat.id] = [];
            }

            acc[chat.id].push({
              id: `pdf-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
              title: title,
              content: content,
              type: 'pdf',
              link: link
            });
            setPdfCount(prevCount => prevCount + 1);
          });
        }
        return acc;
      }, {});

      setResources(prevResources => ({
        ...prevResources,
        ...newResources
      }));
    }

    if (messages && messages.length > 0) {
      messages.forEach(message => {
        if (message.is_ai && !processedMessageIdsRef.current.has(message.id)) {
          processArtifacts(message.content);
          processedMessageIdsRef.current.add(message.id);
        }
      });
    }
  }, [chatsData, messages, processArtifacts]);

  // Updated handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDefaultDisplay(false);

    if (subscription === 'free' && remainingChats <= 0) {
      setSalesModalFeature('Unlimited Chats');
      setShowSalesModal(true);
      return;
    }

    if (isSubmitting) {
      console.log('Submission already in progress. Returning.');
      return;
    }

    setIsSubmitting(true);
    setIsCancelled(false);
    setIsGeneratingResponse(true);
    setGeneratedContent('');

    if (
      (!inputRef.current.trim() && (!pdfFile || pdfFile.length === 0)) ||
      (remainingChats <= 0 && remainingChats != null) ||
      isInputProcessing
    ) {
      console.log('Input is empty, no remaining chats, or input is processing. Returning.');
      setIsSubmitting(false);
      setIsGeneratingResponse(false);
      return;
    }

    const currentInput = inputRef.current;
    const currentTimestamp = new Date().toISOString();
    const tempUserMessageId = Date.now();
    const userMessage = {
      id: tempUserMessageId,
      content: currentInput,
      is_ai: false,
      timestamp: currentTimestamp,
      used_pdf: pdfFile ? true : false,
      pdf_title: pdfFile ? pdfFile.name : null,
    };

    let tempid;
    const currentPdfFile = pdfFile;

    try {
      setIsLoading(true);
      setIsInputProcessing(true);
      setIsSendingFirstMessage(true);
      setIsGenerating(true);

      let currentChatId = chatIdToUse;
      if (!currentChatId || currentChatId === 'new' || currentChatId === 'undefined') {
        const newChat = await createNewChat();
        currentChatId = newChat.id;
        tempid = newChat.id;
        setChatIdToUse(currentChatId);
      }

      setMessages(prevMessages => [...prevMessages, userMessage]);

      setInput('');
      scrollToBottom();
      setIsGeneratingResponse(true);
      inputRef.current = '';
      setPdfFile(null);

      const controller = new AbortController();
      setAbortController(controller);

      const { stream, response } = await sendMessage(currentChatId, currentInput, currentMode, controller.signal, pdfFile ? [pdfFile] : []);
      setIsLoading(false);

      console.log('Full response object:', response);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));

      const usedWeb = response.headers.get('X-Used-Web') === 'true';
      const usedPDF = response.headers.get('X-Used-PDF') === 'true';
      const pdfTitle = response.headers.get('X-PDF-Title');
      const userMessageId = response.headers.get('X-User-Message-Id');
      const aiMessageId = response.headers.get('X-AI-Message-Id');
      const newTitle = response.headers.get('X-New-Title');

      console.log('Headers retrieved:', {
        usedWeb,
        usedPDF,
        pdfTitle,
        userMessageId,
        aiMessageId,
        newTitle
      });

      // Update the user message with the correct ID from the server
      setMessages(prevMessages => prevMessages.map(msg => 
        msg.id === tempUserMessageId 
          ? { ...msg, id: userMessageId, used_pdf: usedPDF, pdf_title: pdfTitle }
          : msg
      ));

      let accumulatedResponse = '';
      let chunkToDisplay = '';
      for await (const chunk of stream) {
        if (chunk.content) {
          const processedChunk = chunk.content;


          if (accumulatedResponse.includes('<artifacts>')) {
            if (!accumulatedResponse.includes('</artifacts>')) {
              accumulatedResponse += processedChunk;
              } else {
              accumulatedResponse += processedChunk;
              chunkToDisplay += processedChunk;
              }
            } else {
            accumulatedResponse += processedChunk;
            chunkToDisplay += processedChunk;
          }

          setGeneratedContent(chunkToDisplay);

        }

        if (accumulatedResponse.endsWith("[Generation stopped]")) {
          break;
        }
      }

      console.log('Response from sendMessage:', stream); // Add this line for debugging

      setIsGeneratingResponse(false);
      
      const processedContent = processArtifacts(accumulatedResponse);

      // Create the AI message with the correct ID
      const aiMessage = {
        id: aiMessageId,
        content: processedContent,
        is_ai: true,
        timestamp: new Date().toISOString(),
        used_web: usedWeb,
        pdf_title: pdfTitle,
      };

      // Update local state with both messages
      setMessages(prevMessages => {
        // Filter out any temporary messages or duplicates
        const filteredMessages = prevMessages.filter(msg => 
          msg.id !== tempUserMessageId && msg.id !== aiMessageId
        );
        
        return [
          ...filteredMessages,
          { ...userMessage, id: userMessageId, used_pdf: usedPDF, pdf_title: pdfTitle },
          aiMessage
        ];
      });

      // Update query cache
      queryClient.setQueryData(['messages', currentChatId], (oldData) => {
        const updatedUserMessage = { ...userMessage, id: userMessageId, used_pdf: usedPDF, pdf_title: pdfTitle };
        return oldData ? [...oldData, updatedUserMessage, aiMessage] : [updatedUserMessage, aiMessage];
      });

      // Only navigate if it's a new chat
      if (!chatIdToUse || chatIdToUse === 'new') {
        navigate(`/chats/${currentChatId}`);
      }

      // Update the chat title if a new title was received
      if (newTitle) {
        // Update the current chat title in the component state
        setCurrentChatTitle(newTitle);
        handleUpdateTitle(newTitle); 
      }

      // Create a resource for the PDF if it was sent
      if (pdfFile) {
        const newResource = {
          id: `pdf-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          title: pdfFile.name,
          content: "PDF content will be processed and displayed here upon refresh.",
          type: 'pdf',
          file: pdfFile.file
        };
        
        handleCreateResource(newResource);
        
        // Clear the pdfFile state after creating the resource
        setPdfFile(null);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setIsGeneratingResponse(false);
      handleSubmissionError(error, currentInput, tempUserMessageId, currentPdfFile);
      if (!chatIdToUse || chatIdToUse === 'new') {
        navigate(`/chats/${tempid}`);
      }
    } finally {
      cleanupSubmission();
    }
  };

  // Updated handleSubmissionError function
  const handleSubmissionError = (error, currentInput, userMessageId, currentPdfFile) => {
    if (error.name === 'AbortError') {
      console.log('Message generation aborted by user.');
    } else {
      console.error('Error sending message:', error);
      showCustomToast('error', 'An error occurred while sending your message. Please try again.');
    }
    removeTemporaryMessages(userMessageId);
    restoreInput(currentInput);
    setPdfFile(currentPdfFile); // Restore PDF file
  };

  // Handle stopping the generation of AI messages
  const handleStopGenerating = () => {
    if (abortController) {
      abortController.abort();
      setIsGenerating(false);
      setIsCancelled(true);
      setIsSubmitting(false);
      
      // Update the last AI message
      updateLastAIMessage("[Generation stopped by user]");
    } else {
      console.warn('No active AbortController to abort.');
    }
  };

  // Add this helper function
  const updateLastAIMessage = (appendText) => {
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
      for (let i = updatedMessages.length - 1; i >= 0; i--) {
        if (updatedMessages[i].is_ai) {
          updatedMessages[i].content += appendText;
          break;
        }
      }
      return updatedMessages;
    });
  };

  // Restore input in the textarea
  const restoreInput = (content) => {
    setInput(content);
    inputRef.current = content;
    if (textareaRef.current) {
      textareaRef.current.value = content;
    }
  };

  // Remove temporary messages in case of error
  const removeTemporaryMessages = (userMessageId, aiMessageId) => {
    setMessages(prevMessages => 
      prevMessages.filter(msg => msg.id !== userMessageId && msg.id !== aiMessageId)
    );
  };

  // Find the last user message for restoration
  const findLastUserMessage = () => {
    for (let i = groupedMessages.length - 1; i >= 0; i--) {
      const lastGroup = groupedMessages[i];
      const lastUserMessage = lastGroup.find(msg => !msg.is_ai);
      if (lastUserMessage) {
        console.log('Found last user message:', lastUserMessage.content);
        return lastUserMessage;
      }
    }
    return null;
  };

  // Cleanup after submission
  const cleanupSubmission = () => {
    setIsLoading(false);
    setIsInputProcessing(false);
    setIsSendingFirstMessage(false);
    setIsGenerating(false);
    setIsSubmitting(false);
    abortControllerRef.current = null;
  };

  // Abort controller cleanup on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        console.log('Component unmounted. Aborting ongoing request.');
      }
    };
  }, []);

  // Handle pending navigation
  useEffect(() => {
    if (pendingNavigation && !abortControllerRef.current?.signal.aborted) {
      navigate(`/chats/${pendingNavigation}`, { replace: true });
      setPendingNavigation(null);
    }
  }, [pendingNavigation, navigate]);

  // Update the toggleSidebar function
  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => !prev);
  }, []);

  // Copy message content to clipboard
  const copyToClipboard = useCallback((content, messageId) => {
    // Remove everything after "<|end of input|>"
    const trimmedContent = content.split('<|endofinput|>')[0].trim();
    
    navigator.clipboard.writeText(trimmedContent).then(() => {
      setCopiedMessageId(messageId);
      setTimeout(() => setCopiedMessageId(null), 2000); // Reset after 2 seconds
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      showCustomToast('error', 'Failed to copy the message.');
    });
  }, []);

  // Update the handleFileChange function
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get only the first file

    if (file && file.type === 'application/pdf') {
      setPdfFile({
        id: `${file.name}-${file.size}-${Date.now()}`, // Unique identifier
        name: file.name,
        file: file,
      });
      showCustomToast('success', 'PDF attached successfully.');
            } else {
      showCustomToast('error', 'Only PDF files are allowed.');
    }

    // Reset the file input
    e.target.value = '';
  };

  // Update the removePDF function
  const removePDF = () => {
    setPdfFile(null);
  };

  const handleAttachClick = () => {
    console.log('handleAttachClick called');
    console.log('Current user subscription:', user.subscription);
    console.log('Current PDF count:', pdfCount);

    if ((user.subscription.tier.toLowerCase() === 'free' || user.subscription.tier.toLowerCase() === 'premium') && pdfCount === 1) {
      console.log('Free or Premium user attempting to upload first PDF');
      setSalesModalFeature('PDF Upload');
      setShowSalesModal(true);
      console.log('Sales modal shown for PDF Upload feature');
      return;
    }

    if (user.subscription.tier.toLowerCase() === 'pro' && pdfCount === 3) {
      console.log('Pro user attempting to upload 4th PDF');
      setSalesModalFeature('additionalPDF Upload');
      setShowSalesModal(true);
      console.log('Sales modal shown for PDF Upload feature');
      return;
    }

    if (user.subscription.tier.toLowerCase() === 'ultimate' && pdfCount > 5) {
      console.log('Ultimate user exceeded PDF limit');
      showCustomToast('error', 'You have reached the maximum number of PDFs per chat.');
      console.log('Error toast shown for exceeding PDF limit');
      return;
    }

    console.log('Triggering file input click');
    fileInputRef.current.click();
  };
  
  const renderResource = useCallback((resource) => (
    <div className="resource-item" onClick={() => handleResourceClick(resource.title, resource.version)}>
      <span className="resource-icon">
        {getResourceIcon(resource.type)}
      </span>
      <div className="resource-info">
        <h3 className="resource-title">{resource.title}</h3>
        <span className="resource-type">{resource.type || 'General'}</span>
      </div>
      <FaChevronRight className="resource-arrow" />
    </div>
  ), [handleResourceClick]);

  // Update the renderMessage function
  const renderMessage = useCallback((message, index, array) => {
    const isEditing = message.id === editingMessageId;
    const hasEdits = message.edit_count > 0;
    const activeIndex = activeBranchIndex[message.id] || 0;
    const activeMessageGroup = groupedMessages.find(group => group[0].id === message.id);
    const activeMessage = activeMessageGroup ? activeMessageGroup[activeIndex] : message;

    const isLastMessage = index === array.length - 1;
    const isLastAIMessage = message.is_ai && array.slice(index + 1).every(msg => !msg.is_ai);

    if (message.id === regeneratingMessageId) {
      return (
        <div key={message.id} className={`message ai ${isSidebarOpen ? 'sidebar-open' : ''}`}>
          <img src={studyBuddyLogo} alt="StudyBuddy AI" className="ai-icon" />
          <div className="message-content">
            <AIResponseRenderer content={regeneratedContent} renderResource={renderResource} />
            {isGeneratingResponse && renderLoadingSymbol()}
          </div>
        </div>
      );
    }

    /**
     * Renders content with support for multiple <quote></quote> tags.
     * Splits the content based on the <quote> tags and renders each part accordingly.
     * 
     * @param {string} content - The message content to render.
     * @returns {JSX.Element} The rendered content with styled quotes.
     */
    const renderContent = (content) => {
      // Use a global regex to find all <quote>...</quote> occurrences
      const quoteRegex = /<quote>(.*?)<\/quote>/gs;
      const parts = [];
      let lastIndex = 0;
      let match;

      // Iterate over all matches
      while ((match = quoteRegex.exec(content)) !== null) {
        const [fullMatch, quoteContent] = match;
        const start = match.index;

        // Add the text before the current quote
        if (start > lastIndex) {
          const text = content.substring(lastIndex, start).trim();
          if (text) {
            parts.push(<MarkdownRenderer key={lastIndex} content={text} />);
          }
        }

        // Add the quoted content with styling
        parts.push(
          <div key={start} className="quote-content">
            {quoteContent}
          </div>
        );

        // Update the lastIndex to the end of the current match
        lastIndex = start + fullMatch.length;
      }

      // Add any remaining text after the last quote
      if (lastIndex < content.length) {
        const text = content.substring(lastIndex).trim();
        if (text) {
          parts.push(<MarkdownRenderer key={lastIndex} content={text} />);
        }
      }

      return <>{parts}</>;
    };

    return (
      <div 
        key={message.id} 
        className={`message ${message.is_ai ? 'ai' : 'user'} ${isSidebarOpen ? 'sidebar-open' : ''}`}
        ref={isLastMessage ? lastMessageRef : null}
      >
        {message.is_ai ? (
          <img src={studyBuddyLogo} alt="StudyBuddy AI" className="ai-icon" />
        ) : null}
        <div className={`message-content ${isEditing ? 'editing' : ''}`}>
          {isEditing ? (
            <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(message.id); }}>
              <textarea
                className="edit-input"
                value={editContent}
                onChange={(e) => setEditContent(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleEditSubmit(message.id);
                  }
                }}
                autoFocus
              />
              <div className="edit-actions">
                <button type="button" onClick={handleEditCancel}>Cancel</button>
                <button type="submit">Save</button>
              </div>
            </form>
          ) : (
            message.is_ai ? (
              <AIResponseRenderer
                content={activeMessage.content}           
                renderResource={renderResource}
              />
            ) : (
              renderContent(activeMessage.content.split('<|endofinput|>')[0].trim())
            )
          )}
          {message.attachment && (
            <div className="attached-pdf">
              <div className="pdf-card">
                <Paperclip size={16} className="pdf-icon" />
                <span className="pdf-name">{message.attachment.name}</span>
              </div>
            </div>
          )}
          {message.is_ai ? (
            <div className="message-footer">
              {isLastMessage && isLoading && renderLoadingSymbol()}
              <div className="message-actions">
                <button 
                  className="action-button" 
                  aria-label="Copy message"
                  title="Copy message"
                  onClick={() => copyToClipboard(activeMessage.content, message.id)}
                >
                  {copiedMessageId === message.id ? <Check size={16} /> : <Copy size={16} />}
                </button>
                {isLastAIMessage && (
                  <button 
                    className="action-button regenerate-button" 
                    aria-label="Regenerate message"
                    title="Regenerate message"
                    onClick={() => handleRegenerateMessage(message.id)}
                    disabled={regeneratingMessageId === message.id}
                  >
                    <RefreshCw size={16} />
                  </button>
                )}
                {message.used_web ? (
                  <div className="web-indicator" title="This message used the web">
                    <Globe size={16} />
                    <span>Web</span>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="message-footer">
              <div className="message-actions">

              {message.pdf_title ? (
                  <div className="pdf-indicator" title="This message used a pdf">
                    <FileText size={16} />
                    <span>{message.pdf_title}</span>
                  </div>
                ) : null}
                {/* Comment out the edit button */}
                {/* <button 
                  className="action-button edit-button" 
                  title="Edit message" 
                  onClick={() => handleEditStart(message)}
                >
                  <Edit size={16} />
                </button> */}
                <button 
                  className="action-button"
                  aria-label="Copy message"
                  title="Copy message"
                  onClick={() => copyToClipboard(activeMessage.content, message.id)}
                >
                  {copiedMessageId === message.id ? <Check size={16} /> : <Copy size={16} />}
                </button>
                <div className="user-icon" aria-label="User icon" title="User icon">
                  {user && user.name ? user.name.charAt(0).toUpperCase() : ''}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="message-info">
          {/* Add timestamp or any other info here */}
        </div>
      </div>
    );
  }, [editingMessageId, editContent, handleEditStart, handleEditSubmit, toggleBranch, activeBranchIndex, groupedMessages, user, copyToClipboard, copiedMessageId, handleRegenerateMessage, regeneratingMessageId, regeneratedContent, lastMessageUsedWeb, handleCreateResource, renderResource]);

  // Render default display when there are no messages
  const renderDefaultDisplay = () => (
    <div className="default-display">
            <img src={CognoraLogo} alt="Cognora Logo" className="imglogo" />
      <ul className="suggestions-list">
        {suggestions.map((suggestion, index) => (
          <li 
            key={index} 
            onClick={() => (remainingChats > 0 || remainingChats == null) ? submitSuggestion(suggestion.text) : null}
            className={`suggestion-item ${(remainingChats <= 0 && remainingChats != null) ? 'disabled' : ''}`}
          >
            {suggestion.icon}
            <span>{suggestion.text}</span>
          </li>
        ))}
      </ul>
      {remainingChats <= 0 && remainingChats != null && (
        <div className="chat-limit-reached">
          <p>Chat limit reached. <Link to="/pricing">Upgrade plan</Link></p>
        </div>
      )}
      {window.innerWidth >= 600 && (
        <div className="input-area empty" >
                  <form className="input-wrapper empty" onSubmit={handleSubmit}>
                    <button
                      type="button"
                      className="attach-button empty"
                      onClick={handleAttachClick}
                      aria-label="Attach PDF"
                      title="Attach PDF"
                    >
                      <Paperclip size={21} />
                    </button>
                    <textarea
                      ref={(el) => {
                        textareaRef.current = el;
                        if (el) {
                          el.value = input;
                          inputRef.current = input;
                          adjustTextareaHeight();
                        }
                      }}
                      value={input}
                      onChange={(e) => {
                        setInput(e.target.value);
                        inputRef.current = e.target.value;
                        adjustTextareaHeight();
                      }}
                      onKeyDown={handleKeyDown}
                      onInput={adjustTextareaHeight}
                      placeholder={isMobile ? "Ask me anything!" : "Ask me anything! I'm here to help you learn."}
                      aria-label="Chat input"
                      className="chat-input scrollable empty"
                      disabled={isSubmitting || (remainingChats <= 0 && remainingChats != null)}
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      id="pdf-upload"
                    />
                    <button 
                      type="submit" 
                      disabled={
                        isSubmitting || 
                        isInputProcessing || 
                        (!input.trim()) || 
                        (remainingChats <= 0 && remainingChats != null)
                      } 
                      aria-label="Send message"
                      className="send-button empty"
                    >
                      <Send size={21} />
                    </button>
                  </form>
            </div>
      )}
    </div>
  );

  // Submit a suggestion as a message
  const submitSuggestion = (suggestion) => {
    console.log('submitSuggestion called', { suggestion });
    if (remainingChats <= 0 && remainingChats != null) {
      console.log('No remaining chats, cannot submit suggestion');
      return;
    }
    setInput(suggestion);
    inputRef.current = suggestion;
    console.log('Input set, calling handleSubmit');
    handleSubmit({ preventDefault: () => {} });
  };

  // Render chat limit banner
  const renderChatLimitBanner = () => {
    if (remainingChats === null || remainingChats === undefined || remainingChats > 4) return null;
    
    let message = '';
    if (remainingChats <= 0) {
      message = (
        <>
          You have no chats left today. <Link to="/pricing">Consider upgrading your plan for more!</Link>
        </>
      );
    } else {
      message = `You have ${remainingChats} chat${remainingChats === 1 ? '' : 's'} left today.`;
    }

    return (
      <div className="chat-limit-banner">
        {message}
      </div>
    );
  };

  // Render loading symbol
  const renderLoadingSymbol = () => (
    <div className="loading-symbol">
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
    </div>
  );

  // Initial textarea height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '23px';
    }
  }, []);

  // Adjust textarea height dynamically
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '23px';
      const newHeight = Math.min(textareaRef.current.scrollHeight, 120);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  // Handle scroll to manage the visibility of the scroll button
  const handleScroll = useCallback(() => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const threshold = 100; // pixels from bottom
      const atBottom = scrollHeight - scrollTop - clientHeight <= threshold;
      setIsUserAtBottom(atBottom);


      if (atBottom) {
        setShowScrollButton(false);
        setNewMessagesCount(0);
      } else {
        setShowScrollButton(true);
      }


      if(isGeneratingResponse && atBottom){
        setShowScrollButton(false);
      }
    }
  }, []);

  // Attach scroll event listener
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
      return () => messagesContainer.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  // Handle key down events for the textarea
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  // Handle search query changes
  useEffect(() => {
    if (searchQuery.trim()) {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = messages.filter(message =>
        message.content.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredMessages(Array.isArray(filtered) ? filtered : []); // Ensure it's an array
    } else {
      setFilteredMessages(Array.isArray(messages) ? messages : []); // Ensure it's an array
    }
  }, [searchQuery, messages]);

  // Add this useEffect to update currentMode when localStorage changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'defaultChatModel') {
        setCurrentMode(e.newValue || 'qa');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Update the mode selection function
  const selectMode = (mode) => {
    setCurrentMode(mode);
    setIsDropdownOpen(false);
  };

  // Add this function to toggle the Resources section
  const toggleResources = () => {
    setIsResourcesVisible(prev => !prev);
    if (!isResourcesVisible && isResourcesExpanded) {
      setIsSidebarOpen(false);
    }else if(isResourcesExpanded){
      setIsSidebarOpen(true);
    }
  };

  // Add this new function to handle closing the Resources section
  const closeResources = () => {
    setIsResourcesVisible(false);
  };

  const getResourceIcon = (type) => {
    switch (type) {
      case 'code':
        return <Code size={16} />;
      case 'essay':
        return <FileText size={16} />;
      case 'pdf':
        return <FileText size={16} />;
      default:
        return <File size={16} />;
    }
  };

  const renderExpanded = (onClose) => {
    return (
      <ExpandedResource
        expandedVersion={expandedVersion}
        resource={expandedResource}
        onClose={onClose}
        onImprove={handleImprove}
        onExplain={handleExplain}
      />
    )
  }

  // Add this new useEffect
  useEffect(() => {
    if (isResourcesExpanded) {
      setIsSidebarOpen(false);
    }
  }, [isResourcesExpanded]);

  // Add this effect after the state declarations
  useEffect(() => {
    if (!isMobile && chatId !== 'new' && chatId !== undefined) {
      setIsResourcesVisible(true);
    }
  }, [chatId, isMobile]);

  return (
    <>
      {/* SEO and Metadata */}
      <Helmet>
        <title>StudyBuddy AI Chat - Personalized Learning Assistant | StuddyBuddy</title>
        <meta name="description" content="Engage with StudyBuddy's AI-powered chat for personalized tutoring, homework help, and academic support across all subjects. Experience adaptive learning with our intelligent study companion." />
        <meta name="keywords" content="StudyBuddy, AI tutor, personalized learning, homework help, academic support, online tutoring, adaptive learning" />
        <link rel="canonical" href="https://studdybuddy.ca/chats/new" />
        <meta property="og:title" content="StudyBuddy AI Chat - Your Personal Learning Assistant" />
        <meta property="og:description" content="Get instant, personalized academic support with StudyBuddy's AI chat. Improve your understanding across all subjects with our intelligent tutoring system." />
        <meta property="og:url" content="https://studdybuddy.ca/chats/new" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="StuddyBuddy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="StudyBuddy AI Chat - Personalized Learning Assistant" />
        <meta name="twitter:description" content="Enhance your learning with StudyBuddy's AI chat. Get instant answers, explanations, and academic support tailored to your needs." />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "SoftwareApplication",
              "name": "StudyBuddy AI Chat",
              "applicationCategory": "EducationalApplication",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
              },
              "description": "AI-powered chat for personalized tutoring and academic support across all subjects.",
              "operatingSystem": "Web",
              "url": "https://studdybuddy.ca/chats/new"
            }
          `}
        </script>
      </Helmet>

      {/* Main Chat Page */}
      <div className={`chat-page ${isSidebarOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-open' : ''}`}>
        {/* Sidebar Component */}
        <Sidebar 
          isOpen={isSidebarOpen} 
          toggleSidebar={toggleSidebar} 
          updateTitle={handleUpdateTitle}
          currentChatId={chatIdToUse}
          currentChatTitle={currentChatTitle}
        />

        {/* Chat Header */}
        <div className={`chat-header ${isSidebarOpen ? 'sidebar-open' : ''}`}>
          <div className="header-left">
            <button 
              className="menu-button" 
              onClick={toggleSidebar} 
              aria-label="Toggle sidebar"
              title="Toggle sidebar"
            >
              <Menu size={24} />
            </button>
            <button 
              className="edit-new-chat-button" 
              onClick={handleAddChat} 
              aria-label="Start new chat"
              title="Start new chat"
            >
              <Edit size={24} />
            </button>
            <div className="mode-selector">
              <button 
                className="mode-selector-button" 
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                aria-label={`Select chat mode. Current mode: ${currentMode}`}
                title={`Select chat mode. Current mode: ${currentMode}`}
              >
                {currentMode.charAt(0).toUpperCase() + currentMode.slice(1)} Mode
                <ChevronDown size={16} />
              </button>
              <div className={`mode-dropdown ${isDropdownOpen ? 'active' : ''}`}>
                <div 
                  className="mode-option mode-qa" 
                  onClick={() => selectMode('qa')}
                  aria-label="Switch to Q&A mode"
                  title="Switch to Q&A mode"
                >
                  <HelpCircle size={16} /> Q&A
                </div>
                <div 
                  className="mode-option mode-tutor" 
                  onClick={() => selectMode('tutor')}
                  aria-label="Switch to Tutor mode"
                  title="Switch to Tutor mode"
                >
                  <Book size={16} /> Tutor
                </div>
                <div 
                  className="mode-option mode-brainstorm" 
                  onClick={() => selectMode('brainstorm')}
                  aria-label="Switch to Brainstorm mode"
                  title="Switch to Brainstorm mode"
                >
                  <Lightbulb size={16} /> Brainstorm
                </div>
                <div 
                  className="mode-option mode-debate" 
                  onClick={() => selectMode('debate')}
                  aria-label="Switch to Debate mode"
                  title="Switch to Debate mode"
                >
                  <MessageSquare size={16} /> Debate
                </div>
                {/* <div className="mode-option mode-research" onClick={() => { setCurrentMode('research'); setIsDropdownOpen(false); }}>
                  <Search size={16} /> Research
                </div> */}
              </div>
            </div>
          </div>
          <div className="header-center">
            <input 
              type="text" 
              placeholder="Search conversations..." 
              className="search-input"
              value={searchQuery}
              onChange={handleSearch}
              aria-label="Search conversations"
              title="Search conversations"
            />
          </div>
          <div className="header-right">
            <Link 
              to="/" 
              className="home-button" 
              aria-label="Go to home page"
              title="Go to home page"
            >
              <Home size={24} />
            </Link>
            <button 
              className={`chat-controls-toggle ${isResourcesVisible ? 'active' : ''}`}
              onClick={toggleResources}
              aria-label={isResourcesVisible ? "Close chat controls" : "Open chat controls"}
              title={isResourcesVisible ? "Close chat controls" : "Open chat controls"}
            >
              <Sliders size={24} />
            </button>
            <Link 
              to="/settings" 
              className="settings-button" 
              aria-label="Open settings"
              title="Open settings"
            >
              <Settings size={24} />
            </Link>
            {/* <div className="user-avatar" aria-label="User avatar">
              {user && user.name ? user.name.charAt(0).toUpperCase() : ''}
            </div> */}
          </div>
        </div>

        {/* Chat Area */}
        <div className={`chat-area ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'} ${isResourcesVisible ? 'resources-open' : ''} ${isResourcesExpanded ? 'resources-expanded' : ''}`}>
          {/* Messages Container */}
          <div 
            className={`messages-container ${isSidebarOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-open' : ''}`} 
            
          >
          <div class="messages-wrapper"
          ref={messagesContainerRef}
          >
            <div className="messages-content">

              {filteredMessages && filteredMessages.length === 0 ? ( // Added filteredMessages check
                searchQuery ? (
                  <div className="no-results">No messages found matching your search.</div>
                ) : (
                  isDefaultDisplay ? renderDefaultDisplay() : null
                )
              ) : (
                <>
                  {filteredMessages && filteredMessages.map((message, index, array) => renderMessage(message, index, array))}
                </>
              )}
              {isGeneratingResponse ? (
                <div className="message ai">
                  <img src={studyBuddyLogo} alt="StudyBuddy AI" className="ai-icon" />
                  <div className="message-content">
                    <AIResponseRenderer content={generatedContent} renderResource={renderResource} />
                    {renderLoadingSymbol()}
                  </div>
                </div>
              ) : null}
              <div ref={messagesEndRef} /> {/* Make sure this is at the very end */}
            </div>
            {showScrollButton && !isDefaultDisplay  && (
              <button 
                className="scroll-to-bottom" 
                onClick={() => {
                  scrollToBottom();
                  setAutoScroll(true);
                }} 
                aria-label="Scroll to bottom"
                title="Scroll to bottom"
              >
                <ChevronDown size={20} />
              </button>
            )}
            <ResourcesSection
              renderExpanded={renderExpanded}           
              ExpandedResource={ExpandedResource}
              isVisible={isResourcesVisible}
              projectTitle={currentChatTitle}
              isExpanded={isResourcesExpanded}
              setIsExpanded={setIsResourcesExpanded}
              onClose={closeResources}
              resources={resources[chatId] || []}  // Pass the resources specific to the current chat
              onResourceClick={handleResourceClick}
              expandedResource={expandedResource}
              setExpandedResource={setExpandedResource}
            />
            </div>
          </div>

          {/* Input Container */}
          <div className={`input-container ${isResourcesVisible ? 'resources-open' : ''}`}>
            {pdfFile && (
              <div className="pdf-attachment">
                <div className="pdf-card">
                  <div className="pdf-thumbnail">
                    {/* PDF icon */}
                    <svg xmlns="http://www.w3.org/2000/svg" className="pdf-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                      <polyline points="14 2 14 8 20 8"></polyline>
                      <line x1="16" y1="13" x2="8" y2="13"></line>
                      <line x1="16" y1="17" x2="8" y2="17"></line>
                      <polyline points="10 9 9 9 8 9"></polyline>
                    </svg>
                  </div>
                  <div className="pdf-info">
                    <span className="pdf-name">{pdfFile.name}</span>
                  </div>
                  <button 
                    className="pdf-remove-button" 
                    onClick={removePDF} 
                    aria-label="Remove PDF"
                    title="Remove PDF"
                  >
                    <X size={16} />
                  </button>
                </div>
              </div>
            )}
            {(filteredMessages && filteredMessages.length > 0) || (filteredMessages && filteredMessages.length === 0 && window.innerWidth < 600) ? (
              <div className="input-area">
                <form className="input-wrapper" onSubmit={handleSubmit}>
                  <button
                    type="button"
                    className="attach-button"
                    onClick={handleAttachClick}
                    aria-label="Attach PDF"
                    title="Attach PDF"
                  >
                    <Paperclip size={21} />
                  </button>
                  <textarea
                    ref={(el) => {
                      textareaRef.current = el;
                      if (el) {
                        el.value = input;
                        inputRef.current = input;
                        adjustTextareaHeight();
                      }
                    }}
                    value={input}
                    onChange={(e) => {
                      setInput(e.target.value);
                      inputRef.current = e.target.value;
                      adjustTextareaHeight();
                    }}
                    onKeyDown={handleKeyDown}
                    onInput={adjustTextareaHeight}
                    placeholder="Type your message here..."
                    aria-label="Chat input"
                    className="chat-input scrollable"
                    disabled={isSubmitting || (remainingChats <= 0 && remainingChats != null)}
                  />
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    id="pdf-upload"
                  />
                  <button 
                    type="submit" 
                    disabled={
                      isSubmitting || 
                      isInputProcessing || 
                      (!input.trim()) || 
                      (remainingChats <= 0 && remainingChats != null)
                    } 
                    aria-label="Send message"
                    className="send-button"
                  >
                    <Send size={21} />
                  </button>
                </form>
              </div>
            ) : null}
            {renderChatLimitBanner()}
          </div>
        </div>
      </div>
      {showToast && <Toast type={toastType} message={toastMessage} />}
      {showSalesModal && (
        <SalesModal feature={salesModalFeature} onClose={closeSalesModal} />
      )}
    </>
  );
};

export default React.memo(Chats);